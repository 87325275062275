import React, { useEffect, useState } from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TooltipMaterial from '@mui/material/Tooltip';
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loader from 'Public/images/loader.gif';
import {
    Button,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import HeatMapSpeed from "Components/user/HeatMap/HeatMapSpeed";
import HeatMapAlertMachine from "Components/user/HeatMap/HeatMapAlertMachine";
import HeatMapAlertPedestrian from "Components/user/HeatMap/HeatMapAlertPedestrian";
import HeatMapAlertVehicle from "Components/user/HeatMap/HeatMapAlertVehicle";
import HeatMapAlertHeavyWeight from "Components/user/HeatMap/HeatMapAlertHeavyWeight";
import "./DashboardSecurite.css";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useFilteredSpeeding } from 'Components/FilteredSpeedingContext'

const DashboardSecurite = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { filteredData } = useFilteredSpeeding();  // Récupérer filteredData du contexte
    const [showAlertModal, setShowAlertModal] = useState(false);  // Nouvel état pour la modal du triangle
    const [dashboardSecurite, setDashboardSecurite] = useState([]);
    const [allDashboardSecurite, setAllDashboardSecurite] = useState([]);
    const [DatasModal, setDatasModal] = useState([]);
    const [heatMapSpeed, setHeatMapSpeed] = useState([]);
    const [Picto, setPicto] = useState([]);
    const [selectedSerialForHeatMapSpeed, setSelectedSerialForHeatMapSpeed] = useState({ name: '', serialNumber: '' });
    const [selectedSerialForHeatMapAlertsPedestrian, setSelectedSerialForHeatMapAlertsPedestrian] = useState({
        name: '',
        serialNumber: ''
    });
    const [selectedSerialForHeatMapAlertsMachine, setSelectedSerialForHeatMapAlertsMachine] = useState({
        name: '',
        serialNumber: ''
    });
    const [selectedSerialForHeatMapAlertsHeavyWeight, setSelectedSerialForHeatMapAlertsHeavyWeight] = useState({
        name: '',
        serialNumber: ''
    });
    const [selectedSerialForHeatMapAlertsVehicle, setSelectedSerialForHeatMapAlertsVehicle] = useState({
        name: '',
        serialNumber: ''
    });
    const [heatMapAlertsPedestrian, setHeatMapAlertsPedestrian] = useState([]);
    const [heatMapAlertsMachine, setHeatMapAlertsMachine] = useState([]);
    const [heatMapHeavyWeight, setHeatMapAlertsHeavyWeight] = useState([]);
    const [heatMapAlertsVehicle, setHeatMapAlertsVehicle] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModalSpeed, setShowModalSpeed] = useState(false);
    const [showModalVehicle, setShowModalVehicle] = useState(false);
    const [showModalPedestrian, setShowModalPedestrian] = useState(false);
    const [showModalMachine, setShowModalMachine] = useState(false);
    const [showModalHeavyWeight, setShowModalHeavyWeight] = useState(false);
    const [selectedStopDate, setSelectedStopDate] = useState(new Date());
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedWorksite, setSelectedWorksite] = useState('');
    const [Worksite, setWorksite] = useState([]);
    const [UserSerialsandNames, setUserSerialsAndNames] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const openModal = () => setShowModalSpeed(true);
    const closeModal = () => setShowModalSpeed(false);
    const handleShowAlertModal = () => setShowAlertModal(true);
    const handleCloseAlertModal = () => setShowAlertModal(false);

    const handleRowClick = (worksiteId, date) => {
        const worksiteEvent = { target: { value: worksiteId } };
        handleWorksiteChange(worksiteEvent);
        handleStartDateChange(new Date(date));
        const daysEvent = { target: { value: '1' } }; // On prend 1 jour, celui du dépassement
        handleNumberOfDaysChange(daysEvent);
        handleUpdate();

        // Fermer la modal
        handleCloseAlertModal();
    };
    const getWorksiteName = (worksiteId) => {
        const worksite = Worksite.find(w => w.worksiteid === worksiteId);
        return worksite ? worksite.worksitename : "Unknown Site";  // Par défaut, afficher "Unknown Site"
    };

    const openModalVehicle = () => setShowModalVehicle(true);
    const closeModalVehicle = () => setShowModalVehicle(false);
    const closeModalPedestrian = () => setShowModalPedestrian(false);
    const openModalPedestrian = () => setShowModalPedestrian(true);
    const closeModalMachine = () => setShowModalMachine(false);
    const openModalMachine = () => setShowModalMachine(true);
    const openModalHeavyWeight = () => setShowModalHeavyWeight(true);
    const closeModalHeavyWeight = () => setShowModalHeavyWeight(false);
    const [numberOfDays, setNumberOfDays] = useState(1);
    const [updateData, setUpdateData] = useState(true);

    const sortedUserDatas = [...dashboardSecurite];

    sortedUserDatas.sort((a, b) => {
        // Comparez d'abord par famille
        const familleComparison = a.familyName.localeCompare(b.familyName);

        // Si les familles sont différentes, retournez la comparaison par famille
        if (familleComparison !== 0) {
            return familleComparison;
        }

        // Si les familles sont les mêmes, comparez par ordre alphanumérique des noms d'appareils
        const nameA = getNamebySerial(a.serial);
        const nameB = getNamebySerial(b.serial);

        return nameA.localeCompare(nameB);
    });

    const handleHeatMapSpeedWithSerial = (name, serialNumber) => {
        setSelectedSerialForHeatMapSpeed({ name, serialNumber });
        setShowModalSpeed(true);
    };

    const handleHeatMapAlertsHeavyWeightWithSerial = (name, serialNumber) => {
        setSelectedSerialForHeatMapAlertsHeavyWeight({ name, serialNumber });
        setShowModalHeavyWeight(true);
    };

    const handleHeatMapAlertsMachineWithSerial = (name, serialNumber) => {
        setSelectedSerialForHeatMapAlertsMachine({ name, serialNumber });
        setShowModalMachine(true);
    };

    const handleOpenModal = (data) => {
        setDatasModal(data)
        setShowModal(true);
    };
    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleHeatMapAlertsVehicleWithSerial = (name, serialNumber) => {
        setSelectedSerialForHeatMapAlertsVehicle({ name, serialNumber });
        setShowModalVehicle(true);
    };

    const handleHeatMapAlertsPedestrianWithSerial = (name, serialNumber) => {
        setSelectedSerialForHeatMapAlertsPedestrian({ name, serialNumber });
        setShowModalPedestrian(true);
    };

    useEffect(() => {

        var start, stop;
        start = selectedStartDate;
        stop = selectedStopDate;

        const dateStart = new Date(selectedStartDate);
        const dateStop = new Date(selectedStopDate);
        dateStart.setHours(0, 0, 1);
        start = new Date(dateStart);

        dateStop.setHours(23, 59, 59);
        stop = new Date(dateStop);

        if (selectedStopDate !== "" && selectedStopDate !== "" && selectedWorksite !== "") {
            setIsLoading(true);
            axios.post(apiUrl + `/api/user/dashboardsecurite`,
                { startdate: start, stopdate: stop, selectedWorksite: selectedWorksite },

                {
                    headers: { 'x-access-token': sessionStorage.getItem("token") }
                })
                .then(async res => {
                    const data = res.data.filter(obj => {
                        const userSerial = UserSerialsandNames.find(userObj => userObj.serial === obj.serial);
                        return userSerial;
                    });
                    if (res.data.length > 0 && UserSerialsandNames.length > 0) {
                        const totalSpeedExceeded = data.reduce((acc, data) => acc + data.countExceeded, 0);
                        const totalHeavyWeights = data.reduce((acc, data) => acc + data.countHeavyWeights, 0);
                        const totalMachineAlerts = data.reduce((acc, data) => acc + data.countMachineAlerts, 0);
                        const totalPedestrianAlerts = data.reduce((acc, data) => acc + data.countPedestrianAlerts, 0);
                        const totalVehicleAlerts = data.reduce((acc, data) => acc + data.countVehicleAlerts, 0);
                        const totalExceededTime = data.reduce((acc, data) => acc + data.totalExceededTime, 0);
                        const maxSpeedValue = Math.max.apply(
                            Math,
                            data.map(function (o) {
                                return o.maxSpeed;
                            })
                        );

                        const familyData = {};
                        for (const DashboardSecu of data) {
                            const FamilyName = await getFamilyByIdDeviceType(DashboardSecu.device_type);
                            DashboardSecu.familyName = FamilyName;

                            if (!familyData[FamilyName]) {
                                familyData[FamilyName] = {
                                    totalSpeed: 0,
                                    count: 0,
                                    speedMax: 0
                                };
                            }


                            familyData[FamilyName].totalSpeed += DashboardSecu.maxSpeed;
                            familyData[FamilyName].count += 1;

                            if (DashboardSecu.maxSpeed > familyData[FamilyName].speedMax) {
                                familyData[FamilyName].speedMax = DashboardSecu.maxSpeed;
                            }
                        }


                        // Calculer la moyenne de la vitesse par famille
                        for (const familyName in familyData) {
                            const familyStats = familyData[familyName];
                            familyStats.speedAverage = familyStats.count > 0 ? familyStats.totalSpeed / familyStats.count : 0;
                        }

                        data.sort((a, b) => a.familyName.localeCompare(b.familyName));


                        const allDashboardSecurite = [{
                            maxSpeed: maxSpeedValue,
                            totalSpeedExceeded: totalSpeedExceeded,
                            totalHeavyWeights: totalHeavyWeights,
                            totalMachineAlerts: totalMachineAlerts,
                            totalPedestrianAlerts: totalPedestrianAlerts,
                            totalVehicleAlerts: totalVehicleAlerts,
                            totalExceededTime: totalExceededTime,
                            maxSpeedFamily: familyData,
                        }];


                        if (maxSpeedValue === 0 && totalSpeedExceeded === 0 && totalHeavyWeights === 0 && totalMachineAlerts === 0 && totalPedestrianAlerts === 0 && totalVehicleAlerts === 0 && totalExceededTime === 0) {
                            setAllDashboardSecurite("")
                            setDashboardSecurite("");
                            setIsLoading(false);
                            setUpdateData(false);
                        } else {
                            setAllDashboardSecurite(allDashboardSecurite)
                            setDashboardSecurite(data);
                            setIsLoading(false);
                            setUpdateData(false);
                        }


                    } else {
                        setAllDashboardSecurite("")
                        setDashboardSecurite("");
                        setIsLoading(false);
                        setUpdateData(false);

                    }

                })
            axios.post(apiUrl + `/api/user/heatmapspeed`,
                { startdate: start, stopdate: stop, selectedWorksite: selectedWorksite },

                {
                    headers: { 'x-access-token': sessionStorage.getItem("token") }
                })
                .then(res => {
                    const data = res.data;
                    setHeatMapSpeed(data);

                })

            axios.post(apiUrl + `/api/user/heatmapmovingdanger`,
                { startdate: start, stopdate: stop, selectedWorksite: selectedWorksite },

                {
                    headers: { 'x-access-token': sessionStorage.getItem("token") }
                })
                .then(res => {
                    const data = res.data;
                    setHeatMapAlertsHeavyWeight(data.HeavyWeights);
                    setHeatMapAlertsVehicle(data.AlertsVehicle);
                    setHeatMapAlertsMachine(data.MachineAlerts);
                    setHeatMapAlertsPedestrian(data.AlertsPedestrian);
                })

            // console.log("UserSerialsandNames: ", UserSerialsandNames);
        }
    }, [UserSerialsandNames]);

    useEffect(() => {
        axios.get(apiUrl + `/api/user/worksite`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                const worksite = res.data.sort((a, b) => a.worksiteid - b.worksiteid);
                setWorksite(worksite);
            })

        axios.get(apiUrl + `/api/user/picto`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                setPicto(res.data);
            })
    }, []);

    useEffect(() => {
        if (selectedWorksite !== "" && updateData === true) {
            var start, stop;
            start = selectedStartDate;
            stop = selectedStopDate;

            const dateStart = new Date(selectedStartDate);
            const dateStop = new Date(selectedStopDate);

            dateStart.setHours(0, 0, 1);
            start = new Date(dateStart);

            dateStop.setHours(23, 59, 59);
            stop = new Date(dateStop);

            if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
                axios.get(apiUrl + `/api/admin/serialsforadmin`, {
                    headers: { 'x-access-token': sessionStorage.getItem("token") }
                })
                    .then(userRes => {
                        // Extraire les numéros de série de l'utilisateur à partir de la réponse
                        const userSerialNumbers = userRes.data.map(user => user.username);
                        // Requête Axios POST pour récupérer les numéros de série de la période
                        axios.post(apiUrl + `/api/user/serialsperiod`,
                            { startdate: start, stopdate: stop, worksiteid: selectedWorksite },
                            { headers: { 'x-access-token': sessionStorage.getItem("token") } })
                            .then(periodRes => {
                                setAllDashboardSecurite("");
                                setDashboardSecurite("");
                                const filteredSerialObjects = periodRes.data.filter(obj => userSerialNumbers.includes(obj.serial));
                                setUserSerialsAndNames(filteredSerialObjects);
                            })
                    })
            } else {
                axios.get(apiUrl + `/api/user/serialsforuser`, {
                    headers: { 'x-access-token': sessionStorage.getItem("token") }
                })
                    .then(userRes => {
                        // Extraire les numéros de série de l'utilisateur à partir de la réponse
                        const userSerialNumbers = userRes.data.map(user => user.username);
                        // Requête Axios POST pour récupérer les numéros de série de la période
                        axios.post(apiUrl + `/api/user/serialsperiod`,
                            { startdate: start, stopdate: stop, worksiteid: selectedWorksite },
                            { headers: { 'x-access-token': sessionStorage.getItem("token") } })
                            .then(periodRes => {
                                setAllDashboardSecurite("");
                                setDashboardSecurite("");
                                const filteredSerialObjects = periodRes.data.filter(obj => userSerialNumbers.includes(obj.serial));
                                setUserSerialsAndNames(filteredSerialObjects);
                            })
                    })
            }
        }
    }, [updateData, selectedStopDate, selectedStartDate, selectedWorksite]);

    useEffect(() => {
        if (Worksite.length > 0) {
            setSelectedWorksite(Worksite[0].worksiteid)
        }
    }, [Worksite]);

    function getFamilyByIdDeviceType(idDeviceType) {
        return axios.post(apiUrl + `/api/user/familybyDevice`, {
            id_device_type: idDeviceType
        }, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                // Renvoyer les données nécessaires
                return res.data[0].device_types_family.family.family_name;
            })
    }

    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
        // if (date) {
        //     calculateStopDate(date, numberOfDays);
        // }
    };

    const handleUpdate = () => {
        setUpdateData(true);
    }

    const handleWorksiteChange = (event) => {
        setSelectedWorksite(event.target.value);
    };

    function getNamebySerial(serial) {
        if (UserSerialsandNames.length > 0) {
            const device = UserSerialsandNames.find(device => device.serial === serial);
            let selectedDevice = "noname";
            let NameDevice = JSON.parse(device.device.name_device);
            let StartDate = selectedStartDate.setHours(0, 0, 1);
            if (NameDevice.length > 1) {

                for (const item of NameDevice) {
                    const itemDate = new Date(item.timestamp);
                    const startDate = new Date(StartDate);

                    if (itemDate < startDate) {
                        selectedDevice = item.nameDevice;
                    }
                }
            } else {
                const itemDate = new Date(NameDevice.timestamp);
                const startDate = new Date(StartDate);
                if (itemDate < startDate)
                    selectedDevice = NameDevice.nameDevice;
            }


            let str = selectedDevice;
            if (str.startsWith('noname') && !sessionStorage.getItem("Roles").includes("ROLE_ADMIN"))
                str = str + '(' + serial + ')';
            return str;
        }
    }

    function getSpeedMaxColorbySerial(SpeedMax) {
        if (UserSerialsandNames.length > 0) {
            const worksite = Worksite.find(worksite => worksite.worksiteid === selectedWorksite);
            const worksiteParameters = JSON.parse(worksite.worksiteparameters);
            if (worksiteParameters !== null) {
                if (worksiteParameters.security.Speedmax_site_global > SpeedMax)
                    return "";
                else
                    return "red"
            } else {
                return '';
            }
        }
    }

    function Goto_Replay(serial) {

        let serialSelected = [];
        serialSelected.push(serial);

        if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
            navigate('/admin/replay', {
                state: {
                    start: new Date(selectedStartDate),
                    stop: new Date(selectedStopDate),
                    worksite: selectedWorksite,
                    serials: serialSelected
                }
            });
        } else {
            navigate('/user/replay', {
                state: {
                    start: new Date(selectedStartDate),
                    stop: new Date(selectedStopDate),
                    worksite: selectedWorksite,
                    serials: serialSelected
                }
            });
        }
    }

    function Goto_ReplayForSpeedMax(date, serial, selectedWorksite) {

        let serialSpeedMax = [];
        serialSpeedMax.push(serial);

        if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
            navigate('/admin/Replay', {
                state: {
                    ts: new Date(date),
                    serials: serialSpeedMax,
                    worksite: selectedWorksite
                }
            });
        } else {
            navigate('/user/Replay', {
                state: {
                    ts: new Date(date),
                    serials: serialSpeedMax,
                    worksite: selectedWorksite
                }
            });
        }
    }

    function getPictoByTypeDevice(type) {

        const picto = Picto.find(device => device.id_device_type === type);

        if (picto)
            return picto.picto;
    }

    useEffect(() => {
        if (selectedStartDate && numberOfDays) {
            calculateStopDate(selectedStartDate, numberOfDays);
        }
    }, [selectedStartDate, numberOfDays]);


    const handleNumberOfDaysChange = (event) => {
        const days = parseInt(event.target.value, 10);
        setNumberOfDays(days);
        // if (selectedStartDate) {
        //     calculateStopDate(selectedStartDate, days);
        // }
    };

    const calculateStopDate = (startDate, days) => {
        const stopDate = new Date(startDate);
        stopDate.setDate(stopDate.getDate() + days - 1);
        setSelectedStopDate(stopDate);
    };


    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <label className='labelStartSecu'>Start : </label>
                <span className='spanStartSecu'>
                    <DatePicker showIcon
                        disabled={isLoading}
                        locale={fr}
                        dateFormat="dd/MM/yyyy"
                        selected={selectedStartDate}
                        onChange={(date) => handleStartDateChange(date)}
                    />
                </span>

                <TextField
                    style={{ marginLeft: "1%" }}
                    type="number"
                    max="2"
                    value={numberOfDays}
                    onChange={handleNumberOfDaysChange}
                    InputProps={{
                        inputProps: { min: 1, max: 2 },
                        endAdornment: <InputAdornment position="end">jours</InputAdornment>,
                    }}
                />

                <Select className='selectWorksiteSecu' value={selectedWorksite} onChange={handleWorksiteChange}>
                    {Worksite.map((worksite, index) => (
                        <MenuItem key={index} value={worksite.worksiteid}>{worksite.worksitename}</MenuItem>
                    ))}
                </Select>

                <Button variant="outlined" size="large" sx={{ height: 55 }} style={{ marginLeft: "1%", width: "5%" }} onClick={handleUpdate}>Update</Button>


                {filteredData.length > 0 && (
                    <TooltipMaterial title="Évènements importants de sécurité" arrow>
                        {/* Utilisation de margin-left: auto pour pousser l'icône à droite */}
                        <div style={{ marginLeft: 'auto', marginRight:'4%', cursor: 'pointer' }} onClick={handleShowAlertModal}>
                            <i className="fas fa-exclamation-triangle" style={{ color: 'red', fontSize: '45px' }}></i>
                        </div>
                    </TooltipMaterial>
                )}


            </div>
            <Modal show={showAlertModal} onHide={handleCloseAlertModal} size='lg' style={{ marginTop: '0%', marginBottom: '0%', transform: 'translate(5%, -10%)' }}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontWeight: 'bold', fontSize: '20px' }}>Alertes de dépassement de vitesse : {filteredData.length}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '50vh', overflowY: 'auto' }}>  {/* Agrandir la modal à 50% */}
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>Worksite</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>Serial</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>Max Speed</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData
                                .sort((a, b) => new Date(a.start) - new Date(b.start))  // Tri par date
                                .map((alert, index) => (
                                    <TableRow
                                        key={index}
                                        hover
                                        onClick={() => handleRowClick(alert.worksiteid, alert.start)}
                                        style={{cursor:'pointer'}}
                                    >
                                        <TableCell style={{ textAlign: 'center' }}>{getWorksiteName(alert.worksiteid)}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{alert.serial}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{alert.speed_max} km/h</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{new Date(alert.start).toLocaleDateString()}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant="secondary"
                        onClick={handleCloseAlertModal}
                        style={{ fontWeight: 'bold', transition: 'all 0.3s ease', backgroundColor: '#f8f9fa', color: '#000', borderColor: '#ccc' }}
                        onMouseEnter={(e) => e.target.style.backgroundColor = '#ccc'}
                        onMouseLeave={(e) => e.target.style.backgroundColor = '#f8f9fa'}
                    >
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
            <div>
                {isLoading ? (
                    <div className='divLoadingSecu'>
                        <img src={Loader} alt="Chargement..." className='imgLoadingSecu' />
                    </div>
                ) : (
                    <>
                        {allDashboardSecurite.length > 0 ?
                            allDashboardSecurite.map((data, index) => (
                                <div key={index} className='frameIndicatorSecu'>
                                    <h2>INDICATEURS CUMULES</h2>
                                    <h4>Periode du : {selectedStartDate.toLocaleDateString("fr-FR")} au {selectedStopDate.toLocaleDateString("fr-FR")}  </h4>
                                    <div>
                                        <div className='allCardSecu'>

                                            <Card variant="outlined" className='cardSecu'>
                                                <CardContent>
                                                    <TooltipMaterial
                                                        title="Nombre d'alertes rouges totales piétons"
                                                        arrow>
                                                        <div>
                                                            <Typography variant="h5" align="center">Alertes
                                                                Piétons</Typography>
                                                            <Typography variant="h4"
                                                                align="center">
                                                                {data.totalPedestrianAlerts > 0 ? (
                                                                    <Button onClick={openModalPedestrian}>
                                                                        <h4>{data.totalPedestrianAlerts}</h4>
                                                                    </Button>
                                                                ) : (
                                                                    <Button disabled={true}><h4
                                                                        style={{ color: 'black' }}>{data.totalPedestrianAlerts}</h4>
                                                                    </Button>
                                                                )}
                                                            </Typography>
                                                        </div>
                                                    </TooltipMaterial>
                                                </CardContent>
                                            </Card>

                                            <Card variant="outlined" className='cardSecu'>
                                                <CardContent>
                                                    <TooltipMaterial
                                                        title="Nombre d'alertes rouges totales vehicules"
                                                        arrow>
                                                        <div>

                                                            <Typography variant="h5" align="center">Alertes
                                                                VL</Typography>
                                                            <Typography variant="h4"
                                                                align="center">
                                                                {data.totalVehicleAlerts > 0 ? (
                                                                    <Button onClick={openModalVehicle}>
                                                                        <h4>{data.totalVehicleAlerts}</h4>
                                                                    </Button>
                                                                ) : (
                                                                    <Button disabled={true}><h4
                                                                        style={{ color: 'black' }}>{data.totalVehicleAlerts}</h4>
                                                                    </Button>
                                                                )}
                                                            </Typography>
                                                        </div>
                                                    </TooltipMaterial>
                                                </CardContent>
                                            </Card>


                                            <Card variant="outlined" className='cardSecu'>
                                                <CardContent>
                                                    <TooltipMaterial
                                                        title="Nombre d'alertes rouges totales poids lourds"
                                                        arrow>
                                                        <div>
                                                            <Typography variant="h5" align="center">Alertes
                                                                PL</Typography>
                                                            <Typography variant="h4"
                                                                align="center">
                                                                {data.totalHeavyWeights > 0 ? (
                                                                    <Button onClick={openModalHeavyWeight}>
                                                                        <h4>{data.totalHeavyWeights}</h4>
                                                                    </Button>
                                                                ) : (
                                                                    <Button disabled={true}><h4
                                                                        style={{ color: 'black' }}>{data.totalHeavyWeights}</h4>
                                                                    </Button>
                                                                )}</Typography>
                                                        </div>
                                                    </TooltipMaterial>
                                                </CardContent>
                                            </Card>

                                            <Card variant="outlined" className='cardSecu'>
                                                <CardContent>
                                                    <TooltipMaterial
                                                        title="Nombre d'alertes rouges totales engins"
                                                        arrow>
                                                        <div>
                                                            <Typography variant="h5" align="center">Alertes
                                                                Engins</Typography>
                                                            <Typography variant="h4"
                                                                align="center">
                                                                {data.totalMachineAlerts > 0 ? (
                                                                    <Button onClick={openModalMachine}>
                                                                        <h4>{data.totalMachineAlerts}</h4>
                                                                    </Button>
                                                                ) : (
                                                                    <Button disabled={true}><h4
                                                                        style={{ color: 'black' }}>{data.totalMachineAlerts}</h4>
                                                                    </Button>
                                                                )}
                                                            </Typography>
                                                        </div>
                                                    </TooltipMaterial>
                                                </CardContent>
                                            </Card>
                                        </div>

                                        <div className='allCardSecu'>
                                            <Card variant="outlined" className='cardSecu'>
                                                <CardContent>
                                                    <div>
                                                        <Typography variant="h5" align="center">Marche AR avec angle mort</Typography>
                                                    </div>
                                                </CardContent>
                                            </Card>

                                            <Card variant="outlined" className='cardSecu'>
                                                <CardContent>
                                                    <div>
                                                        <Typography variant="h5" align="center">Talonnage</Typography>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                            <Card variant="outlined" className='cardSecu'>
                                                <CardContent>
                                                    <div>
                                                        <Typography variant="h5" align="center">piéton transporté sur un engin</Typography>
                                                    </div>
                                                </CardContent>
                                            </Card>

                                            <Card variant="outlined" className='cardSecu'>
                                                <CardContent>
                                                    <div>
                                                        <Typography variant="h5" align="center">no go zone</Typography>
                                                    </div>
                                                </CardContent>
                                            </Card>

                                            <Card variant="outlined" className='cardSecu'>
                                                <CardContent>
                                                    <div>
                                                        <Typography variant="h5" align="center">freinage
                                                            urgence</Typography>
                                                    </div>
                                                </CardContent>
                                            </Card>


                                        </div>
                                        <div className='allCardSecu'>
                                            <Card variant="outlined" className='cardSecu'>
                                                <CardContent>
                                                    <TooltipMaterial
                                                        title="Nombre de fois que la vitesse a dépassé la vitesse max du site"
                                                        arrow>
                                                        <div>
                                                            <Typography variant="h5" align="center">Excès de vitesse </Typography>
                                                            <Typography variant="h4"
                                                                align="center">{data.totalSpeedExceeded > 0 ? (
                                                                    <Button onClick={openModal}>
                                                                        <h4>{data.totalSpeedExceeded}</h4>
                                                                    </Button>
                                                                ) : (
                                                                    <Button disabled={true}><h4
                                                                        style={{ color: 'black' }}>{data.totalSpeedExceeded}</h4>
                                                                    </Button>
                                                                )}</Typography>
                                                        </div>
                                                    </TooltipMaterial>
                                                </CardContent>
                                            </Card>

                                            <Card variant="outlined" className='cardSecu'>
                                                <CardContent>
                                                    <TooltipMaterial
                                                        title="Vitesse la plus haute relevée sur tous les appareils du site"
                                                        arrow>
                                                        <div>
                                                            <Typography variant="h5" align="center">Vitesse
                                                                max</Typography>
                                                            <Typography variant="h3" align="center"
                                                                style={{ backgroundColor: getSpeedMaxColorbySerial(data.maxSpeed) }}><Button
                                                                    disabled={true}><h4
                                                                        style={{ color: 'black' }}>{data.maxSpeed} Km/h</h4>
                                                                </Button></Typography>
                                                            <Button className='btnFamilySecu'
                                                                variant="contained"
                                                                onClick={() => handleOpenModal(data.maxSpeedFamily)}>Voir
                                                                par Famille</Button>
                                                        </div>
                                                    </TooltipMaterial>
                                                </CardContent>
                                            </Card>

                                            <Card variant="outlined" className='cardSecu'>
                                                <CardContent>
                                                    <TooltipMaterial
                                                        title="Temps dépassé au total supérieur à la vitesse max du site"
                                                        arrow>
                                                        <div>
                                                            <Typography variant="h5" align="center">Temps
                                                                en excès</Typography>
                                                            <Typography variant="h4"
                                                                align="center"><Button disabled={true}><h4
                                                                    style={{ color: 'black' }}>{data.totalExceededTime} s </h4>
                                                                </Button></Typography>
                                                        </div>
                                                    </TooltipMaterial>
                                                </CardContent>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            ))
                            : (
                                <p className='pNoDataSecu'>Aucune donnée disponible.</p>
                            )}
                        {sortedUserDatas.length > 0 ? (
                            <>
                                <Table className='tableContainerSecu'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align={"center"}>Picto</TableCell>
                                            <TableCell align={"center"}>Appareil</TableCell>
                                            <TableCell align={"center"}>Vitesse max</TableCell>
                                            <TableCell align={"center"}>Excès de vitesse</TableCell>
                                            <TableCell align={"center"}>Temps en excès</TableCell>
                                            <TableCell align={"center"}>Alerte VL</TableCell>
                                            <TableCell align={"center"}>Alerte PL</TableCell>
                                            <TableCell align={"center"}>Alerte Engin</TableCell>
                                            <TableCell align={"center"}>Alerte Piéton</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sortedUserDatas.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell
                                                    align={"center"}><img
                                                        src={require("Public/images/picto/" + getPictoByTypeDevice(item.device_type))}
                                                        alt="" className='imgPictoSecu' /></TableCell>
                                                {sessionStorage.getItem("Roles").includes("ROLE_ADMIN") ? <TableCell
                                                    align={"center"}><Button
                                                        onClick={() => Goto_Replay(item.serial)}>{getNamebySerial(item.serial)}({item.serial})</Button></TableCell> :
                                                    <TableCell
                                                        align={"center"}> <Button
                                                            onClick={() => Goto_Replay(item.serial)}>{getNamebySerial(item.serial)}</Button>
                                                    </TableCell>}
                                                <TableCell align={"center"}><p
                                                    style={{ backgroundColor: getSpeedMaxColorbySerial(item.maxSpeed) }}>
                                                    <Button
                                                        onClick={() => Goto_ReplayForSpeedMax(item.maxSpeedTimestamp, item.serial, selectedWorksite)}>{item.maxSpeed} km/h</Button>
                                                </p>
                                                </TableCell>
                                                <TableCell align={"center"}>{item.countExceeded > 0 ? <Button
                                                    onClick={() => handleHeatMapSpeedWithSerial(getNamebySerial(item.serial), item.serial)}> {item.countExceeded} </Button> : item.countExceeded}</TableCell>
                                                <TableCell align={"center"}>{item.totalExceededTime} s</TableCell>
                                                <TableCell align={"center"}>{item.countVehicleAlerts > 0 ? <Button
                                                    onClick={() => handleHeatMapAlertsVehicleWithSerial(getNamebySerial(item.serial), item.serial)}> {item.countVehicleAlerts} </Button> : item.countVehicleAlerts}</TableCell>
                                                <TableCell align={"center"}>{item.countHeavyWeights > 0 ? <Button
                                                    onClick={() => handleHeatMapAlertsHeavyWeightWithSerial(getNamebySerial(item.serial), item.serial)}> {item.countHeavyWeights} </Button> : item.countHeavyWeights}</TableCell>
                                                <TableCell align={"center"}>{item.countMachineAlerts > 0 ? <Button
                                                    onClick={() => handleHeatMapAlertsMachineWithSerial(getNamebySerial(item.serial), item.serial)}> {item.countMachineAlerts} </Button> : item.countMachineAlerts}</TableCell>
                                                <TableCell align={"center"}>{item.countPedestrianAlerts > 0 ? <Button
                                                    onClick={() => handleHeatMapAlertsPedestrianWithSerial(getNamebySerial(item.serial), item.serial)}> {item.countPedestrianAlerts} </Button> : item.countPedestrianAlerts}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </>
                        ) : null}
                    </>
                )}
            </div>
            {heatMapSpeed.length > 0 ?
                <HeatMapSpeed
                    show={showModalSpeed}
                    onHide={closeModal}
                    heatMapSpeed={heatMapSpeed}
                    serial={selectedSerialForHeatMapSpeed.serialNumber}
                    name={selectedSerialForHeatMapSpeed.name}
                /> : ""
            }

            {heatMapAlertsVehicle.length > 0 ?
                <HeatMapAlertVehicle
                    show={showModalVehicle}
                    onHide={closeModalVehicle}
                    heatMapMovingDanger={heatMapAlertsVehicle}
                    serial={selectedSerialForHeatMapAlertsVehicle.serialNumber}
                    name={selectedSerialForHeatMapAlertsVehicle.name}
                /> : ""
            }

            {heatMapAlertsMachine.length > 0 ?
                <HeatMapAlertMachine
                    show={showModalMachine}
                    onHide={closeModalMachine}
                    heatMapMovingDanger={heatMapAlertsMachine}
                    serial={selectedSerialForHeatMapAlertsMachine.serialNumber}
                    name={selectedSerialForHeatMapAlertsMachine.name}
                /> : ""
            }

            {heatMapAlertsPedestrian.length > 0 ?
                <HeatMapAlertPedestrian
                    show={showModalPedestrian}
                    onHide={closeModalPedestrian}
                    heatMapMovingDanger={heatMapAlertsPedestrian}
                    serial={selectedSerialForHeatMapAlertsPedestrian.serialNumber}
                    name={selectedSerialForHeatMapAlertsPedestrian.name}
                /> : ""
            }

            {heatMapHeavyWeight.length > 0 ?
                <HeatMapAlertHeavyWeight
                    show={showModalHeavyWeight}
                    onHide={closeModalHeavyWeight}
                    heatMapMovingDanger={heatMapHeavyWeight}
                    serial={selectedSerialForHeatMapAlertsHeavyWeight.serialNumber}
                    name={selectedSerialForHeatMapAlertsHeavyWeight.name}
                /> : ""
            }

            <Modal size="lg" show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Vitesse max par famille</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Famille</TableCell>
                                        <TableCell>Vitesse max(km/h)</TableCell>
                                        <TableCell>Vitesse moyenne(km/h)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(DatasModal).map(([family, vitesse]) => (
                                        <TableRow key={family}>
                                            <TableCell>{family}</TableCell>
                                            <TableCell>{vitesse.speedMax?.toFixed(2)} km/h</TableCell>
                                            <TableCell>{vitesse.speedAverage?.toFixed(2)} km/h</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

        </>

    )
}
export default DashboardSecurite