import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import axios from "axios";
import Loader from 'Public/images/loader.gif';
import { MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper, Typography, Modal, Box, Button, TextField, Grid, CardContent, Card } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from 'xlsx-js-style';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import useWebSocket from "react-use-websocket"

const apiUrl = process.env.REACT_APP_API_URL;
const WS_URL = process.env.REACT_APP_WS_URL;

function DashboardProductionCumulative() {

  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedStopDate, setSelectedStopDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWorksite, setSelectedWorksite] = useState("all");
  const [ratioWorksite, setRatioWorksite] = useState("");
  const [selectedAllWorksite, setSelectedAllWorksite] = useState("");
  const [Worksite, setWorksite] = useState([]);
  const [Picto, setPicto] = useState([]);
  const [deviceName, setDeviceName] = useState([]);
  const [devices, setDevices] = useState([]);
  const [deviceHistoryList, setDeviceHistoryList] = useState({});
  const [numberOfDaysWeekYear, setNumberOfDaysWeekYear] = useState(1);
  const [dateOption, setDateOption] = useState('jours');
  const [updateData, setUpdateData] = useState(true);
  const [cumulProd, setCumulProd] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRotations, setSelectedRotations] = useState([]);
  const [allCumulProd, setAllCumulProd] = useState([]);
  const [stockMaterial, setStockMaterial] = useState([]);
  const [stock, setStock] = useState([]);
  const [material, setMaterial] = useState([]);
  const [dataStockMaterial, setDataStockMaterial] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState("all");
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [selectedStock, setSelectedStock] = useState("all");
  const [selectedMaterial, setSelectedMaterial] = useState("all");
  const [dataFilter, setDataFilter] = useState(null);
  const [ratioDatas, setRatioDatas] = useState([]);
  const [dailyUserDatas, setDailyUserDatas] = useState([]);
  const [filteredConnected, setFilteredConnected] = useState([]);
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [deviceTable, setDeviceTable] = useState([]);
  const userid = sessionStorage.getItem("userId")
  const channelname = "connected";

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    WS_URL,
    {
      queryParams: { userid, channelname },
      share: false,
      shouldReconnect: () => true,
    },
  )

  useEffect(() => {
    if (lastJsonMessage !== null) {
      setFilteredConnected(lastJsonMessage)
      // console.log(lastJsonMessage);
    }
  }, [lastJsonMessage])

  useEffect(() => {
    const fetchDeviceTypesWithFamilies = async () => {
      try {
        const response = await axios.get(apiUrl + '/api/device-types-with-families', {
          headers: { 'x-access-token': sessionStorage.getItem("token") }
        });

        // Stocke les types de devices avec les familles
        setDeviceTypes(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des types de device avec familles:", error);
      }
    };

    // Appelle la fonction pour récupérer les types de devices avec familles de devices
    fetchDeviceTypesWithFamilies();
  }, []);

  useEffect(() => {
    // Fonction pour mettre à jour le tableau des devices
    const updateDeviceTable = () => {
      const filteredData = FiltreData();  // Récupère les données depuis WebSocket
      // console.log(filteredData);
      const updatedDeviceTable = [...deviceTable];  // Copie de l'état actuel

      // Boucle sur les données filtrées pour mettre à jour ou ajouter des devices
      filteredData.forEach((newDevice) => {
        // console.log(newDevice);
        const existingDeviceIndex = updatedDeviceTable.findIndex(device => device.serial === newDevice.serial);

        // Trouver type et family (nom_family_device) correspondant au type
        const deviceTypeData = deviceTypes.find(type => type.id_device_type === newDevice.type);
        const deviceTypeName = deviceTypeData ?
          deviceTypeData.nom_device_type
          : 'Unknown type'
        const deviceFamilyName = deviceTypeData
          ? deviceTypeData.family_device_type.family_device.nom_family_device.trim() // On récupère le nom de la famille
          : 'Unknown family';

        if (existingDeviceIndex !== -1) {
          // Si le device existe déjà, mise à jour
          updatedDeviceTable[existingDeviceIndex] = {
            ...updatedDeviceTable[existingDeviceIndex],
            lat: newDevice.lat,
            lng: newDevice.lng,
            type: newDevice.type,
            type_name: deviceTypeName,
            angle: newDevice.angle,
            connected: newDevice.connected,
            family_name: deviceFamilyName,
          };
        } else {
          // Sinon, on ajoute le device au tableau
          updatedDeviceTable.push({
            ...newDevice,
            type_name: deviceTypeName,
            family_name: deviceFamilyName,
          });
        }
      });
      // Mise à jour de l'état
      setDeviceTable(updatedDeviceTable);
    };

    // Appeler la fonction pour mettre à jour le tableau des devices après avoir récupéré les types
    if (deviceTypes.length > 0) {
      updateDeviceTable();
    }

  }, [filteredConnected, deviceTypes]);  // Met à jour lorsque filteredConnected ou deviceTypes changent

  const FiltreData = () => {
    // console.log("FiltreDataFunc: ", filteredConnected);
    const filteredData = Object.entries(filteredConnected)
      .filter(([id, filtered]) => filtered.last_keepAlive.gps_status)
      .map(([id, filtered]) => {
        const serial = filtered.SerialStr;
        const type = filtered.last_keepAlive.current_CB_type;
        const lat = filtered.last_keepAlive.current_position.latitude;
        const lng = filtered.last_keepAlive.current_position.longitude;
        const angle = filtered.last_keepAlive.current_angle;
        const connected = filtered.IsConnected;

        return {
          serial,
          lat,
          lng,
          type,
          angle,
          connected
        };
      });
    return filteredData;
  }

  const handleOpen = (rotations) => {
    setSelectedRotations(rotations);
    setOpen(true);
  };

  useEffect(() => {
    if (selectedWorksite !== "all") {
      setCumulProd(allCumulProd.filter(item => item.worksite_id === selectedWorksite))
    }
    else {
      setCumulProd(allCumulProd);
    }

    setSelectedDevice('all')
    setSelectedFilter('all');
    setSelectedStock('all');
    setSelectedMaterial('all');
  }, [selectedWorksite])

  useEffect(() => {
    var start, stop;
    start = selectedStartDate;
    stop = selectedStopDate;

    const dateStart = new Date(selectedStartDate);
    const dateStop = new Date(selectedStopDate);

    start = dateStart.setHours(0, 0, 1);
    stop = dateStop.setHours(23, 59, 59);

    if (selectedAllWorksite.length > 0) {
      const timeDataBySerialAndDate = {};
      setIsLoading(true);

      const requests = selectedAllWorksite.map(thisWorksite =>
        axios.post(apiUrl + `/api/user/reportprodevent`, {
          startdate: new Date(start),
          stopdate: new Date(stop),
          selectedWorksite: thisWorksite
        }, {
          headers: { 'x-access-token': sessionStorage.getItem("token") }
        }).then(res => ({ res, thisWorksite }))
      );

      Promise.all(requests)
        .then(responses => {
          responses.forEach(({ res, thisWorksite }) => {
            if (res.data.length > 0) {
              res.data.forEach(reportprodevent => {
                const serial = reportprodevent.serial;

                reportprodevent.periods.forEach(period => {
                  const periodDate = new Date(period.start).toISOString().split('T')[0]; // Format YYYY-MM-DD

                  if (!timeDataBySerialAndDate[serial]) {
                    timeDataBySerialAndDate[serial] = {};
                  }
                  if (!timeDataBySerialAndDate[serial][periodDate]) {
                    timeDataBySerialAndDate[serial][periodDate] = { timeWork: 0, timePost: 0 };
                  }

                  if (reportprodevent.device_type !== 24) {
                    const startTime = new Date(period.start);
                    const stopTime = new Date(period.stop);
                    const difference = (stopTime - startTime) / 1000;

                    timeDataBySerialAndDate[serial][periodDate].timeWork += period.time_work;
                    timeDataBySerialAndDate[serial][periodDate].timePost += difference;
                  }
                });
              });
            }
          });

          // Calcul des ratios pour chaque serial et chaque date
          const ratioRouleStop = [];
          Object.entries(timeDataBySerialAndDate).forEach(([serial, dateData]) => {
            Object.entries(dateData).forEach(([date, { timeWork, timePost }]) => {
              const ratio = timePost > 0 ? parseFloat(((timeWork / timePost) * 100).toFixed(1)) : 0;
              ratioRouleStop.push({ date, serial, ratio });
            });
          });

          setRatioDatas(ratioRouleStop);
          setIsLoading(false);
        })
        .catch(error => {
          console.error("Erreur lors de l'exécution des requêtes:", error);
          setIsLoading(false);
          setRatioDatas([]);
          setDailyUserDatas([]);
        });
    } else {
      setRatioDatas([]);
      setDailyUserDatas([]);
    }
  }, [selectedStartDate, selectedStopDate, selectedAllWorksite]);


  const handleSelectDeviceChange = (event) => {
    setSelectedDevice(event.target.value);
  };
  const handleSelectedMaterial = (event) => {

    if (event.target.value !== 'all') {
      const result = {
        total: { totalRotation: 0, totalTaskDuration: 0 },
        filteredData: []
      };
      cumulProd.forEach((item) => {
        const rotations = JSON.parse(item.rotation);
        rotations.forEach((rotation) => {
          // Extraire stock et material de chaque rotation
          const { stock, material, rotation_count, task_duration } = rotation;

          // Comparer avec le stock et material sélectionnés

          if (`${material}` === event.target.value && selectedStock === "all") {
            result.filteredData.push({
              serial: item.serial,
              rotation_count,
              task_duration,
              stock,
              material
            });

            result.total.totalRotation += rotation_count;
            result.total.totalTaskDuration += task_duration;
          }
          else if (`${material}` === event.target.value && selectedStock === stock) {
            result.filteredData.push({
              serial: item.serial,
              rotation_count,
              task_duration,
              stock,
              material
            });

            result.total.totalRotation += rotation_count;
            result.total.totalTaskDuration += task_duration;
          }
        });
      });
      setDataStockMaterial(result);
    }
    else {
      if (selectedStock !== 'all') {
        const result = {
          total: { totalRotation: 0, totalTaskDuration: 0 },
          filteredData: []
        };
        cumulProd.forEach((item) => {
          const rotations = JSON.parse(item.rotation);
          rotations.forEach((rotation) => {
            // Extraire stock et material de chaque rotation
            const { stock, material, rotation_count, task_duration } = rotation;

            // Comparer avec le stock et material sélectionnés


            if (selectedStock === stock) {
              result.filteredData.push({
                serial: item.serial,
                rotation_count,
                task_duration,
                stock,
                material
              });

              result.total.totalRotation += rotation_count;
              result.total.totalTaskDuration += task_duration;
            }
          });
        });
        setDataStockMaterial(result);
      }
    }
    setSelectedMaterial(event.target.value);

  }
  const handleSelectedStock = (event) => {


    if (event.target.value !== 'all') {
      const result = {
        total: { totalRotation: 0, totalTaskDuration: 0 },
        filteredData: []
      };
      cumulProd.forEach((item) => {
        const rotations = JSON.parse(item.rotation);
        rotations.forEach((rotation) => {
          // Extraire stock et material de chaque rotation
          const { stock, material, rotation_count, task_duration } = rotation;

          // Comparer avec le stock et material sélectionnés
          if (`${stock}` === event.target.value && selectedMaterial === 'all') {
            result.filteredData.push({
              serial: item.serial,
              rotation_count,
              task_duration,
              stock,
              material
            });

            result.total.totalRotation += rotation_count;
            result.total.totalTaskDuration += task_duration;
          }
          else if (`${stock}` === event.target.value && selectedMaterial === material) {
            result.filteredData.push({
              serial: item.serial,
              rotation_count,
              task_duration,
              stock,
              material
            });

            result.total.totalRotation += rotation_count;
            result.total.totalTaskDuration += task_duration;
          }
        });
      });
      setDataStockMaterial(result);
    }
    else {
      if (selectedMaterial !== 'all') {
        const result = {
          total: { totalRotation: 0, totalTaskDuration: 0 },
          filteredData: []
        };
        cumulProd.forEach((item) => {
          const rotations = JSON.parse(item.rotation);
          rotations.forEach((rotation) => {
            // Extraire stock et material de chaque rotation
            const { stock, material, rotation_count, task_duration } = rotation;

            if (selectedMaterial === material) {
              result.filteredData.push({
                serial: item.serial,
                rotation_count,
                task_duration,
                stock,
                material
              });

              result.total.totalRotation += rotation_count;
              result.total.totalTaskDuration += task_duration;
            }
          });
        });
        setDataStockMaterial(result);
      }
    }
    setSelectedStock(event.target.value);
  }
  const handleSelectedFilter = (event) => {
    if (event.target.value === "rotation") {
      let totalRotationsSum = 0;
      let stocksCumul = {};
      const stockSet = new Set();
      const materialSet = new Set();

      cumulProd.forEach((item) => {
        // Calculer le total global des rotations
        totalRotationsSum += item.rotation_cnt;
        // Accumuler les stocks et matériaux
        const rotations = JSON.parse(item.rotation);
        rotations.forEach((rotation) => {
          const stock = rotation.stock;
          const material = rotation.material;
          const taskDuration = rotation.task_duration;
          const rotation_count = rotation.rotation_count;
          const key = `${stock}_${material}`;

          stockSet.add(`${rotation.stock}`);
          materialSet.add(`${rotation.material}`);
          // Ajouter au cumul des stocks et matériaux
          if (!stocksCumul[key]) {
            stocksCumul[key] = { stock, material, totalDuration: taskDuration, rotation_count };
          } else {
            // Si la clé existe déjà, cumuler les valeurs
            stocksCumul[key].totalDuration += taskDuration;
            stocksCumul[key].rotation_count += rotation_count;
          }
        });
      });

      // Trier et sélectionner le top 10 des serials par rotations
      const sortedByRotation = [...cumulProd].sort((a, b) => b.rotation_cnt - a.rotation_cnt).slice(0, 10);

      const stockCumul = Object.entries(stocksCumul).map(([key, details]) => ({
        stock: details.stock,
        material: details.material,
        totalDuration: details.totalDuration,
        rotation_count: details.rotation_count,
      }));
      const sortedStockCumul = stockCumul.sort((a, b) => b.rotation_count - a.rotation_count);

      setStock(Array.from(stockSet));
      setMaterial(Array.from(materialSet));
      // Sérialisation des données dans un tableau
      const serialized = {
        totalRotations: totalRotationsSum,
        stockCumul: sortedStockCumul,
        top10Rotations: sortedByRotation.map(item => ({
          serial: item.serial,
          rotationCount: item.rotation_cnt,
        })),
      };

      setDataFilter(serialized);

    }
    else if (event.target.value === 'fuel') {
      let totalFuelSum = 0;
      cumulProd.forEach((item) => {
        // Calculer le total global des rotations
        totalFuelSum += item.fuel;
        // Accumuler les stocks et matériaux

        // Trier et sélectionner le top 10 des serials par rotations
      })
      const sortedByFuel = [...cumulProd].sort((a, b) => b.fuel - a.fuel).slice(0, 10);

      // Sérialisation des données dans un tableau
      const serialized = {
        totalFuel: totalFuelSum,
        top10Fuel: sortedByFuel.map(item => ({
          serial: item.serial,
          fuel: item.fuel,
        })),
      };


      setDataFilter(serialized);
    }
    else if (event.target.value === 'timepost') {
      let totalTimePostSum = 0;
      cumulProd.forEach((item) => {

        totalTimePostSum += item.time_post;

      })
      const sortedByTimePost = [...cumulProd].sort((a, b) => b.time_post - a.time_post).slice(0, 10);

      // Sérialisation des données dans un tableau
      const serialized = {
        totalTimePost: totalTimePostSum,
        top10TimePost: sortedByTimePost.map(item => ({
          serial: item.serial,
          time_post: item.time_post,
        })),
      };


      setDataFilter(serialized);
    }
    setSelectedFilter(event.target.value);

  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleWorksiteChange = (event) => {
    setSelectedWorksite(event.target.value);
  };

  const handleUpdate = () => {
    setUpdateData(true);
  }

  function formatDate(date) {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {

    setIsLoading(true);
    var start, stop;
    start = selectedStartDate;

    const dateStart = new Date(selectedStartDate);
    let dateStop = new Date(selectedStartDate);
    if (dateOption === "jours") {
      dateStop.setDate(dateStop.getDate() + numberOfDaysWeekYear - 1)
    }
    else if (dateOption === "semaines") {
      dateStop.setDate(dateStop.getDate() + (numberOfDaysWeekYear * 7))
    }
    else if (dateOption === "mois") {
      dateStop.setDate(dateStop.getDate() + (numberOfDaysWeekYear * 30))
    }
    else if (dateOption === "années") {
      dateStop.setDate(dateStop.getDate() + (numberOfDaysWeekYear * 365))

    }

    dateStart.setHours(0, 0, 1);
    start = new Date(dateStart);
    dateStop.setHours(23, 59, 59);
    stop = new Date(dateStop);
    setSelectedStopDate(dateStop);

    let formattedStart = formatDate(start);
    let formattedStop = formatDate(stop);

    if (selectedAllWorksite !== "") {
      axios.post(apiUrl + `/api/user/cumulprod`,
        { startdate: formattedStart, stopdate: formattedStop, selectedWorksite: selectedAllWorksite },

        {
          headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
        .then(res => {
          setExcelData(res.data);
          const cumulateData = (data) => {
            const cumulativeData = {};
            data.forEach(item => {

              const serial = item.serial.trim();
              const rotations = JSON.parse(item.rotation);
              const time_post = Number(item.time_post);
              const cadence = item.cadence;
              const timestamp = item.ts;

              if (!cumulativeData[serial]) {

                cumulativeData[serial] = {
                  ...item,
                  time_post: time_post,
                  fuel: item.fuel,
                  distance_traveled: item.distance_traveled,
                  rotation: rotations,
                  rotation_cnt: item.rotation_cnt,
                  total_cadence: cadence,
                  ...(time_post !== null ? { time_post_by_date: [{ ts: timestamp, time_post: time_post }] } : { time_post_by_date: [] }),
                  entry_count: 1,
                  ...(cadence !== null ? { cadence_by_date: [{ ts: timestamp, cadence: cadence }] } : { cadence_by_date: [] })
                };
              } else {
                cumulativeData[serial].rotation_cnt += item.rotation_cnt;
                cumulativeData[serial].time_post += time_post;
                cumulativeData[serial].distance_traveled += item.distance_traveled;
                cumulativeData[serial].fuel += item.fuel;
                cumulativeData[serial].total_cadence += cadence;
                rotations.forEach(rotation => {
                  const existingRotation = cumulativeData[serial].rotation.find(r =>
                    r.stock === rotation.stock && r.material === rotation.material
                  );

                  if (existingRotation) {
                    existingRotation.rotation_count += rotation.rotation_count;
                    existingRotation.task_duration += rotation.task_duration;
                  } else {
                    cumulativeData[serial].rotation.push(rotation);
                  }
                });
                cumulativeData[serial].entry_count += 1;
                if (cadence !== null) {
                  cumulativeData[serial].cadence_by_date.push({ ts: timestamp, cadence: cadence });
                }

                if (time_post !== null) {
                  cumulativeData[serial].time_post_by_date.push({ ts: timestamp, time_post: time_post });
                }
              }
            });

            Object.keys(cumulativeData).forEach(serial => {
              const entryCount = cumulativeData[serial].entry_count;
              cumulativeData[serial].average_cadence = cumulativeData[serial].total_cadence / entryCount; // Moyenne de cadence
            });

            // Transforme l'objet en tableau
            return Object.values(cumulativeData).map(item => ({
              ...item,
              rotation: JSON.stringify(item.rotation)
            }));
          };

          setCumulProd(cumulateData(res.data))
          setAllCumulProd(cumulateData(res.data));
          setDataFilter(null);
          setSelectedFilter("all");
          setSelectedDevice("all");
          setSelectedWorksite("all");
          setSelectedStock("all");
          setSelectedMaterial("all");
          setIsLoading(false);;

        })
    }
    setUpdateData(false);


    // console.log("selectedAllWorksite: ", selectedAllWorksite);
    // console.log("Worksite: ", Worksite);
  }, [updateData, selectedAllWorksite])

  const secondesEnDuree = (secondes) => {
    const heures = Math.floor(secondes / 3600);
    const minutes = Math.floor((secondes % 3600) / 60);
    const secondesRestantes = secondes % 60;
    return `${heures.toString().padStart(2, "0")}h${minutes.toString().padStart(2, "0")}m${Math.floor(secondesRestantes).toString().padStart(2, "0")}s`;
  };

  useEffect(() => {
    axios.get(apiUrl + `/api/user/worksite`, {
      headers: { 'x-access-token': sessionStorage.getItem("token") }
    })
      .then(res => {
        const worksite = res.data.sort((a, b) => a.worksiteid - b.worksiteid);
        setWorksite(worksite);
      })

    axios.get(apiUrl + `/api/user/picto`, {
      headers: { 'x-access-token': sessionStorage.getItem("token") }
    })
      .then(res => {
        setPicto(res.data);
      })
  }, []);

  useEffect(() => {
    if (selectedStartDate && selectedStopDate && (selectedStartDate != selectedStopDate)) {
      if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
        axios.get(apiUrl + `/api/admin/device`, {
          headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
          .then(res => {
            setDeviceName(res.data.sort((a, b) => a.username.localeCompare(b.username)));
            setDevices(res.data);
          })
      } else {
        axios.post(`${apiUrl}/api/user/devicehisto`, {
          reqStartDate: selectedStartDate,
          reqStopDate: selectedStopDate
        }, {
          headers: {
            'x-access-token': sessionStorage.getItem("token"),
            'Content-Type': 'application/json'
          }
        })
          .then(res => {
            setDeviceName(res.data.sort((a, b) => a.username.localeCompare(b.username)));
            setDevices(res.data);
          })
          .catch(err => {
            console.error("Erreur Axios :", err);
          });
      }
    }
    // console.log("selectedStartDate: ", selectedStartDate,"selectedStopDate: ", selectedStopDate);
  }, [selectedStartDate, selectedStopDate])

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleChangeDateOption = (event) => {
    setDateOption(event.target.value);
  };

  useEffect(() => {
    if (Worksite.length > 0) {
      const worksiteIds = Worksite.map(worksite => worksite.worksiteid);
      // worksiteIds.push(7); // Ajout à la main du worksite unknown
      setSelectedAllWorksite(worksiteIds);
    }
  }, [Worksite]);

  const handleNumberOfDaysWeekYearChange = (event) => {
    const days = parseInt(event.target.value, 10);
    setNumberOfDaysWeekYear(days);
  };

  useEffect(() => {
    const fetchDeviceHistory = async () => {
      try {
        const response = await axios.get(apiUrl + '/api/user/devicehisto', {
          headers: { 'x-access-token': sessionStorage.getItem("token") }
        });

        const historyList = response.data.map(record => ({
          serial: record.serial,
          idClient: record.idClient,
          date_start: record.date_start,
          date_stop: record.date_stop
        }));

        // console.log(`[DEBUG] Historique des devices (${historyList.length}):`, historyList);
        setDeviceHistoryList(historyList);
      } catch (error) {
        console.error("[ERROR] Erreur lors de la récupération de l'historique des devices :", error);
      }
    };

    fetchDeviceHistory();
  }, []);

  function getNamebySerial(serial) {
    // console.log("[DEBUG] Appel de getNamebySerial avec serial :", serial);

    if (deviceName.length > 0 && deviceHistoryList.length > 0) {
      const device = deviceName.find(device => device.username.trim() === serial.trim());
      let selectedDeviceNames = [];

      if (device !== undefined) {
        // console.log("[DEBUG] Appareil trouvé :", device);

        let NameDevice;

        try {
          NameDevice = JSON.parse(device.name_device);
          // console.log("[DEBUG] name_device après parsing JSON :", NameDevice);
        } catch (error) {
          console.error("[ERROR] Erreur de parsing JSON :", error);
          return "noname";
        }

        if (!Array.isArray(NameDevice)) {
          NameDevice = [NameDevice];
        }

        const flattenedNameDevice = NameDevice.flat();
        // console.log("[DEBUG] Liste aplanie des noms d'appareils :", flattenedNameDevice);

        const historyRecords = deviceHistoryList.filter(record => {
          return record.serial.trim() === serial.trim();
        });

        // console.log("[DEBUG] Historique récupéré pour le serial :", historyRecords);

        if (historyRecords.length === 0) {
          // console.warn(`[WARN] Aucun historique trouvé pour le serial : ${serial}`);
          return "noname";
        }

        const filteredHistory = historyRecords.filter(record => {
          const dateStart = new Date(record.date_start);
          const dateStop = record.date_stop ? new Date(record.date_stop) : new Date();
          // console.log("[DEBUG] Vérification de la période : dateStart =", dateStart, ", dateStop =", dateStop);

          return (
            (selectedStartDate <= dateStop && selectedStopDate >= dateStart) ||
            (selectedStartDate >= dateStart && selectedStartDate <= dateStop)
          );
        });

        // console.log("[DEBUG] Historique après filtrage par période :", filteredHistory);

        if (filteredHistory.length === 0) {
          // console.warn(`[WARN] Aucun historique correspondant à la période pour le serial : ${serial}`);
          return "noname";
        }

        filteredHistory.sort((a, b) => new Date(a.date_start) - new Date(b.date_start));

        for (const history of filteredHistory) {
          const dateStart = new Date(history.date_start);
          const dateStop = history.date_stop ? new Date(history.date_stop) : null;

          let nameFound = "noname";
          for (const item of flattenedNameDevice) {
            const itemDate = new Date(item.timestamp);
            if (itemDate >= dateStart && (!dateStop || itemDate <= dateStop)) {
              nameFound = item.nameDevice;
              // console.log("[DEBUG] Nom trouvé :", nameFound, "pour la période :", dateStart, "à", dateStop);
            }
          }

          // Ajouter la date de début seulement si elle est dans la période sélectionnée
          if (dateStart >= selectedStartDate && dateStart <= selectedStopDate) {
            selectedDeviceNames.push(`${nameFound} (${dateStart.toLocaleDateString()})`);
          } else {
            selectedDeviceNames.push(nameFound);
          }
        }

        // console.log("[DEBUG] Noms d'appareils trouvés pour le serial :", selectedDeviceNames);
      }

      return selectedDeviceNames.length > 0 ? selectedDeviceNames.join(" / ") : "noname";
    }

    // console.log("[DEBUG] Aucun nom trouvé, retour de 'noname'");
    return "noname";
  }



  const generateExcelData = (data, isCumul, start, stop) => {
    const excelData = [];

    // Ajouter en-têtes personnalisés avec des styles
    if (isCumul) {
      excelData.push([
        { v: "Start", s: { fill: { fgColor: { rgb: "FFFF00" } } } },
        { v: "Stop", s: { fill: { fgColor: { rgb: "FFFF00" } } } }
      ]);
      excelData.push([start, stop]);
      excelData.push([]);

      // En-têtes de colonne avec du style
      excelData.push([
        { v: "Appareil", s: { fill: { fgColor: { rgb: "FFCC00" } } } },
        { v: "Carburant (L)", s: { fill: { fgColor: { rgb: "FFCC00" } } } },
        { v: "Temps de Poste", s: { fill: { fgColor: { rgb: "FFCC00" } } } },
        { v: "Distance Parcourue (km)", s: { fill: { fgColor: { rgb: "FFCC00" } } } },
        { v: "Total Rotation", s: { fill: { fgColor: { rgb: "FFCC00" } } } },
        { v: "Cadence (tr/h)", s: { fill: { fgColor: { rgb: "FFCC00" } } } },
      ]);
    } else {
      excelData.push([
        { v: "Date", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
        { v: "Appareil", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
        { v: "Carburant (L)", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
        { v: "Temps de Poste", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
        { v: "Distance Parcourue (km)", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
        { v: "Total Rotation", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
        { v: "Cadence (tr/h)", s: { fill: { fgColor: { rgb: "D3D3D3" } } } }
      ]);
    }

    // Ajouter les données de chaque entrée avec style
    data.forEach(item => {

      const row = isCumul
        ? [
          { v: getNamebySerial(item.serial), s: { fill: { fgColor: { rgb: "FFFFFF" } } } }, // Appareil
          { v: item.fuel, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },                  // Carburant
          { v: secondesEnDuree(item.time_post), s: { fill: { fgColor: { rgb: "FFFFFF" } } } },  // Temps de poste
          { v: `${(item.distance_traveled / 1000)}`, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },  // Distance
          { v: item.rotation_cnt, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },           // Rotation
          { v: item.average_cadence.toFixed(2), s: { fill: { fgColor: { rgb: "FFFFFF" } } } }           // Moyenne Cadence
        ]
        : [
          { v: item.ts, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },                    // Date
          { v: getNamebySerial(item.serial), s: { fill: { fgColor: { rgb: "FFFFFF" } } } },  // Appareil
          { v: item.fuel, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },                  // Carburant
          { v: secondesEnDuree(item.time_post), s: { fill: { fgColor: { rgb: "FFFFFF" } } } },  // Temps
          { v: `${(item.distance_traveled / 1000)}km`, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },  // Distance
          { v: item.rotation_cnt, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },       // Rotation
          { v: item.cadence !== null ? item.cadence : 0, s: { fill: { fgColor: { rgb: "FFFFFF" } } } }           // Cadence
        ];
      excelData.push(row);
    });
    excelData.push([]);
    // Ajouter des rotations si applicable

    let isbool = false;

    data.forEach(item => {
      if (!isCumul && item.rotation_cnt > 0) {
        if (!isbool) {
          excelData.push([{ v: "Date", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
          { v: "Stock", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
          { v: "Materiau", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
          { v: "Nombre de rotation", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
          { v: "Durée", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
          ]);
          isbool = true;
        }

        const rotations = JSON.parse(item.rotation);
        rotations.forEach(rotation => {
          excelData.push([
            { v: item.ts, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },            // Date
            { v: rotation.stock, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },     // Stock
            { v: rotation.material, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },  // Matériau
            { v: rotation.rotation_count, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },  // Rotation
            { v: secondesEnDuree(rotation.task_duration), s: { fill: { fgColor: { rgb: "FFFFFF" } } } }  // Durée
          ]);
        });
      }
    });

    return excelData;
  };

  const exportToExcel = (data) => {
    // Générer les données pour Excel
    const groupedData = groupDataBySerialNumber(data);

    const wb = XLSX.utils.book_new();

    const excelDataCumul = generateExcelData(cumulProd, true, selectedStartDate.toLocaleDateString("fr-FR"), selectedStopDate.toLocaleDateString("fr-FR"));

    const ws = XLSX.utils.aoa_to_sheet(excelDataCumul);
    const columnWidth = 30;
    const columnCount = cumulProd.length;
    ws['!cols'] = Array(columnCount).fill({ wch: columnWidth });

    XLSX.utils.book_append_sheet(wb, ws, `Cumul`);

    for (const serialNumber in groupedData) {
      if (groupedData.hasOwnProperty(serialNumber)) {
        const excelData = generateExcelData(groupedData[serialNumber], false);
        const ws = XLSX.utils.aoa_to_sheet(excelData);
        const columnWidth = 30;
        const columnCount = Object.keys(excelData[0]).length;
        ws['!cols'] = Array(columnCount).fill({ wch: columnWidth });

        // Ajouter la feuille de calcul au classeur
        XLSX.utils.book_append_sheet(wb, ws, `${getNamebySerial(serialNumber)}`);
      }
    }
    // Enregistrer le fichier
    XLSX.writeFile(wb, 'export.xlsx');
  };

  const generateExcelDataV2 = (dataActivities, dataCumul, start, stop) => {
    const excelData = [];
    console.log(devices);
    // En-tête pour la plage de dates
    excelData.push([
      { v: "Date de début", s: { fill: { fgColor: { rgb: "FFFF00" } } } },
      { v: "Date de fin", s: { fill: { fgColor: { rgb: "FFFF00" } } } }
    ]);
    excelData.push([start, stop]);
    excelData.push([]);

    // Section 1 : Détail des activités
    excelData.push([
      { v: "Détail des activités", s: { fill: { fgColor: { rgb: "6A5ACD" } } } },
      { v: "", s: { fill: { fgColor: { rgb: "6A5ACD" } } } },
      { v: "", s: { fill: { fgColor: { rgb: "6A5ACD" } } } },
      { v: "", s: { fill: { fgColor: { rgb: "6A5ACD" } } } },
      { v: "", s: { fill: { fgColor: { rgb: "6A5ACD" } } } },
      { v: "", s: { fill: { fgColor: { rgb: "6A5ACD" } } } },
      { v: "", s: { fill: { fgColor: { rgb: "6A5ACD" } } } },
    ]);
    excelData.push([
      { v: "Date", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
      { v: "Engin", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
      { v: "Worksite", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
      { v: "Stock", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
      { v: "Materiau", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
      { v: "Nombre de rotation", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
      { v: "Durée", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
    ]);

    // Itération sur chaque engin pour les activités journalières
    dataActivities.forEach(item => {
      const worksiteName = Worksite.find(w => w.worksiteid === item.worksite_id)?.worksitename || "Inconnu";
      const deviceName = getNamebySerial(item.serial);
      // console.log("item: ", item)//.find(d => d) item.username);

      const deviceType = deviceTable.find(d => d.serial === item.serial)?.type
      // console.log("activ deviceType: ", deviceTable);
      if (deviceType && ((deviceType >= 10 && deviceType <= 16) || deviceType === 41)) {
        excelData.push([
          { v: item.date, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },                          // Date
          { v: deviceName, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },                         // Engin
          { v: worksiteName, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },                       // Worksite
          { v: item.stock, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },                         // Stock
          { v: item.material, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },                      // Matériau
          { v: item.rotation_count, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },                // Nombre de rotation
          { v: secondesEnDuree(item.task_duration), s: { fill: { fgColor: { rgb: "FFFFFF" } } } } // Durée
        ]);
      }
    });
    excelData.push([]);

    // Section 2 : Cumul par jour
    excelData.push([
      { v: "Cumul par jour", s: { fill: { fgColor: { rgb: "6A5ACD" } } } },
      { v: "", s: { fill: { fgColor: { rgb: "6A5ACD" } } } },
      { v: "", s: { fill: { fgColor: { rgb: "6A5ACD" } } } },
      { v: "", s: { fill: { fgColor: { rgb: "6A5ACD" } } } },
      { v: "", s: { fill: { fgColor: { rgb: "6A5ACD" } } } },
      { v: "", s: { fill: { fgColor: { rgb: "6A5ACD" } } } },
      { v: "", s: { fill: { fgColor: { rgb: "6A5ACD" } } } },
      { v: "", s: { fill: { fgColor: { rgb: "6A5ACD" } } } },
      { v: "", s: { fill: { fgColor: { rgb: "6A5ACD" } } } },
      { v: "", s: { fill: { fgColor: { rgb: "6A5ACD" } } } },
    ]);
    excelData.push([
      { v: "Date", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
      { v: "Engin", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
      { v: "Worksite", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
      { v: "Carburant (L)", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
      { v: "Temps de Poste", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
      { v: "Distance Parcourue (km)", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
      { v: "Total Rotation", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
      { v: "Tonnage", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
      { v: "Ratio roule/stop (%)", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
      { v: "Cadence (tr/h)", s: { fill: { fgColor: { rgb: "D3D3D3" } } } }
    ]);
    // console.log("data: ", data);
    dataCumul.forEach(item => {
      const device = devices.find(d => d.username.trim() === item.serial.trim());
      const worksiteNames = Array.isArray(item.worksite_ids) && item.worksite_ids.length > 0
        ? item.worksite_ids
          .map(worksiteId => {
            const worksite = Worksite.find(w => w.worksiteid === worksiteId);
            // console.log('worksiteId:', worksiteId, '=>', worksite ? worksite.worksitename : 'Inconnu');
            return worksite?.worksitename || "Inconnu";
          })
          .join(', ')
        : "Inconnu"; // Valeur par défaut si item.worksite_ids est undefined ou vide

      /* TEST */
      let ratio = { serial: item.serial, ratio: "unknown" };

      if (Array.isArray(ratioDatas) && ratioDatas.length > 0) {
        console.log("[DEBUG] ratioDatas existe et contient des éléments.", ratioDatas);

        const foundRatio = ratioDatas.find(r => {
          const serialTrimmed = r.serial?.trim();
          const itemSerialTrimmed = item.serial?.trim();
          const itemDate = item.date;

          // Parser les dates
          const ratioDate = r.date ? new Date(r.date).toISOString().split('T')[0] : null;
          const itemDateFormatted = itemDate ? new Date(itemDate).toISOString().split('T')[0] : null;

          console.log(`[DEBUG] Comparaison pour serial: ${itemSerialTrimmed} et date: ${itemDateFormatted}`);
          console.log(`[DEBUG] Ratio date: ${ratioDate}, Période sélectionnée: date = ${itemDateFormatted}`);

          // Vérification du serial ET de la date exacte
          return (
            serialTrimmed === itemSerialTrimmed &&
            ratioDate === itemDateFormatted
          );
        });

        if (foundRatio) {
          console.log(`[DEBUG] Ratio trouvé pour serial ${item.serial} et date ${item.date}:`, foundRatio);
          ratio = foundRatio;
        } else {
          console.log(`[DEBUG] Ratio non trouvé pour serial: ${item.serial} et date: ${item.date}, affichage 'unknown'.`);
        }
      } else {
        console.log("[DEBUG] ratioDatas est vide ou n'est pas un tableau.");
        console.log(`[DEBUG] item.serial: ${item.serial}`);
      }




      const deviceType = deviceTable.find(d => d.serial === item.serial)?.type
      // console.log("cumul deviceType: ", deviceType);
      if (deviceType && ((deviceType >= 10 && deviceType <= 16) || deviceType === 41)) {
        excelData.push([
          { v: item.date, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },                            // Date
          { v: getNamebySerial(item.serial), s: { fill: { fgColor: { rgb: "FFFFFF" } } } },         // Engin
          { v: worksiteNames, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },                         // Worksite
          { v: item.fuel, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },                            // Carburant (L)
          { v: secondesEnDuree(item.time_post), s: { fill: { fgColor: { rgb: "FFFFFF" } } } },      // Temps de poste
          { v: `${(item.distance_traveled / 1000)}`, s: { fill: { fgColor: { rgb: "FFFFFF" } } } }, // Distance (km)
          { v: item.rotation_cnt, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },                    // Total rotations
          { v: (item.rotation_cnt * device.payload) / 1000, s: { fill: { fgColor: { rgb: "FFFFFF" } } } }, // Tonnage (total roatations * capacité engin)
          { v: ratio.ratio, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },                                // Ratio roule/stop
          { v: item.cadence.toFixed(2), s: { fill: { fgColor: { rgb: "FFFFFF" } } } }       // Moyenne cadence (tr/h)
        ]);
      }
    });
    excelData.push([]);
    return excelData;
  }

  const exportToExcelV2 = (data) => {
    // Trier les données par date
    const sortedData = data.sort((a, b) => new Date(a.ts) - new Date(b.ts));
    // console.log("sortedData", sortedData);

    // Grouper et cumuler les données par jour et par engin
    const dailyCumulativeData = sortedData.reduce((cumul, item) => {
      const date = item.ts;
      const serial = item.serial.trim(); // On utilise le serial comme identifiant d'engin
      const key = `${date}_${serial}`; // Clé unique pour chaque jour et chaque engin

      if (!cumul[key]) {
        // Si première entrée pour ce jour et cet engin: init
        cumul[key] = {
          date,
          serial,
          device_type: item.device_type,
          distance_traveled: item.distance_traveled || 0,
          fuel: item.fuel || 0,
          rotation_cnt: item.rotation_cnt || 0,
          time_post: item.time_post || 0,
          rotations: JSON.parse(item.rotation) || [],
          cadence: item.cadence || 0,
          worksite_ids: new Set([item.worksite_id]) // Initialiser avec le premier worksite_id
        };
      } else {
        // Sinon, cumuler les valeurs
        cumul[key].distance_traveled += item.distance_traveled || 0;
        cumul[key].fuel += item.fuel || 0;
        cumul[key].rotation_cnt += item.rotation_cnt || 0;
        cumul[key].time_post += item.time_post || 0;
        cumul[key].cadence += item.cadence || 0;
        cumul[key].rotations = cumul[key].rotations.concat(JSON.parse(item.rotation) || []);
        cumul[key].worksite_ids.add(item.worksite_id);
        // cumul[key].worksite_ids = Array.from(cumul[key].worksite_ids);
      }

      return cumul;
    }, {});

    // Convertir les Sets en tableaux après la réduction
    Object.values(dailyCumulativeData).forEach(item => {
      item.worksite_ids = Array.from(item.worksite_ids);
    });

    // Transformer le résultat en tableau d'objets
    const dailyCumul = Object.values(dailyCumulativeData);
    // console.log("dailyCumul: ", dailyCumul);

    // Initialiser le tableau final activitiesDetails
    const activitiesDetails = [];

    // Parcourir chaque entrée de dailyCumul triée
    dailyCumul.forEach(item => {
      const { date, serial, rotations, worksite_ids } = item;

      const worksiteId = Array.isArray(worksite_ids) ? worksite_ids[0] : worksite_ids;

      // Pour chaque rotation, créer une entrée distincte dans activitiesDetails
      rotations.forEach(rotation => {
        activitiesDetails.push({
          date,
          serial,
          stock: rotation.stock,
          material: rotation.material,
          rotation_count: rotation.rotation_count,
          task_duration: rotation.task_duration,
          worksite_id: worksiteId,
        });
      });
    });
    // console.log("activitiesDetails: ", activitiesDetails);

    const wb = XLSX.utils.book_new();

    // const excelDataCumul = generateExcelDataV2(cumulProd, true, selectedStartDate.toLocaleDateString("fr-FR"), selectedStopDate.toLocaleDateString("fr-FR"));
    const excelDataCumul = generateExcelDataV2(activitiesDetails, dailyCumul, selectedStartDate.toLocaleDateString("fr-FR"), selectedStopDate.toLocaleDateString("fr-FR"));

    const ws = XLSX.utils.aoa_to_sheet(excelDataCumul);
    const defaultWidth = 25;
    const columnWidths = [25, 25, 18, 18, 18, 20, 18, 18, 18, 18]; // Largeurs personnalisées pour certaines colonnes
    const columnCount = cumulProd.length;

    // Créer un tableau de colonnes avec des largeurs personnalisées ou la valeur par défaut
    const cols = Array.from({ length: columnCount }, (_, index) => {
      return { wch: columnWidths[index] !== undefined ? columnWidths[index] : defaultWidth };
    });

    ws['!cols'] = cols;

    XLSX.utils.book_append_sheet(wb, ws, `Prod`);

    // Enregistrer le fichier
    XLSX.writeFile(wb, 'export.xlsx');
  }

  const groupDataBySerialNumber = (data) => {
    return data.reduce((acc, item) => {
      const serialNumber = item.serial.trim();
      if (!acc[serialNumber]) {
        acc[serialNumber] = [];
      }
      // On ajoute l'élément au serial correspondant
      acc[serialNumber].push(item);

      // On trie le groupe par date du plus récent au plus ancien
      acc[serialNumber].sort((a, b) => new Date(b.ts) - new Date(a.ts));
      return acc;
    }, {});
  };

  function getPictoByTypeDevice(type) {
    const picto = Picto.find(device => device.id_device_type === type);
    if (picto)
      return picto.picto;
  }

  const CustomTooltipRotation = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
          <p>{`Appareil : ${getNamebySerial(data.serial)}`}</p>
          <p>{`Rotations : ${data.rotationCount}`}</p>
        </div>
      );
    }
    return null;
  };

  const CustomTooltipFuel = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
          <p>{`Appareil : ${getNamebySerial(data.serial)}`}</p>
          <p>{`Fuel : ${data.fuel} L`} </p>
        </div>
      );
    }
    return null;
  };

  const CustomTooltipTimePost = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
          <p>{`Appareil : ${getNamebySerial(data.serial)}`}</p>
          <p>{`Temps de poste : ${secondesEnDuree(data.time_post)}`} </p>
        </div>
      );
    }
    return null;
  };

  const generateDateRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  const fillMissingDates = (data, cadence) => {
    // Générer toutes les dates dans la plage
    const allDates = generateDateRange(new Date(selectedStartDate), new Date(selectedStopDate));
    // Créer un objet pour un accès facile aux dates dans les données
    const dataByDate = {};
    data.forEach(item => {
      const dateKey = formatDate(new Date(item.ts));
      dataByDate[dateKey] = item;
    });

    // Remplir les dates manquantes avec des valeurs par défaut (cadence 0 par exemple)
    const filledData = allDates.map(date => {
      const dateKey = formatDate(new Date(date));
      let data;
      if (cadence) {
        data = { ts: dateKey, cadence: 0 };
      }
      else {
        data = { ts: dateKey, time_post: 0 }
      }
      return dataByDate[dateKey] || data; // cadence à 0 si la date n'existe pas
    });

    return filledData;
  };

  function distanceFormat(distance) {
    const kilometers = Math.floor(distance / 1000); // Les kilomètres sont la partie entière
    const meters = distance % 1000;
    return kilometers + "kms " + meters + "m";
  }

  return (
    <div>
      <div>
        <label className='labelStart'>Start : </label>
        <span className='spanStart'>
          <DatePicker showIcon
            disabled={isLoading}
            locale={fr}
            dateFormat="dd/MM/yyyy"
            selected={selectedStartDate}
            onChange={(date) => handleStartDateChange(date)}
          />
        </span>

        <TextField
          style={{ marginLeft: "1%", width: '80px' }}
          type="number"
          value={numberOfDaysWeekYear}
          onChange={handleNumberOfDaysWeekYearChange}
          inputProps={{ min: 1 }}
        />

        <Select
          style={{ marginLeft: '1%' }}
          labelId="date-select-label"
          id="date-select"
          value={dateOption}
          onChange={handleChangeDateOption}
          label="Choisir une option"
        >
          <MenuItem value="jours">Jours</MenuItem>
          <MenuItem value="semaines">Semaines</MenuItem>
          <MenuItem value="mois">Mois</MenuItem>
          <MenuItem value="années">Année</MenuItem>
        </Select>


        <Button variant="outlined" size="large" sx={{ height: 55 }} style={{ marginLeft: "1%", marginRight: "1%", width: "5%" }} onClick={handleUpdate}>Update</Button>
        {/* <Button title='export excel' className='btnExportExcel' onClick={() => exportToExcel(excelData)}><FontAwesomeIcon icon={faFileCsv} size="lg" /></Button> */}
        <Button title='export excel' className='btnExportExcel' style={{ justifyContent: "left" }} onClick={() => exportToExcelV2(excelData)}><FontAwesomeIcon icon={faFileExcel} size="2xl" color='green' /></Button>
      </div>

      <div>
        {isLoading ? (
          <div className='divLoading'>
            <img src={Loader} alt="Chargement..." className='imgLoading' />
          </div>
        ) : (
          <>
            <div className='textCenter'>
              <h2>CUMULS PRODUCTION</h2>
              <h4>Periode du : {selectedStartDate.toLocaleDateString("fr-FR")} au {selectedStopDate.toLocaleDateString("fr-FR")} </h4>
            </div>
            {allCumulProd.length > 0 ?
              <>Site :
                <Select value={selectedWorksite} onChange={handleWorksiteChange}>
                  <MenuItem value="all">All</MenuItem>
                  {Worksite.map((worksite, index) => (
                    <MenuItem key={index} value={worksite.worksiteid}>{worksite.worksitename}</MenuItem>
                  ))}
                </Select></> : ""
            }
            {cumulProd.length > 0 ?
              (<>
                Device :
                <Select value={selectedDevice} onChange={handleSelectDeviceChange}>
                  <MenuItem selected key="all" value="all">All</MenuItem>
                  {cumulProd.map((item, index) => (
                    <MenuItem key={index} value={item.serial}>{getNamebySerial(item.serial)}</MenuItem>
                  ))}
                </Select> </>) : ""}
            {cumulProd.length > 0 && selectedDevice === 'all' ?
              (<>
                Filtre :
                <Select value={selectedFilter} onChange={handleSelectedFilter}>
                  <MenuItem selected key="all" value="all">All</MenuItem>
                  <MenuItem selected key="rotation" value="rotation">Rotation</MenuItem>
                  <MenuItem selected key="timepost" value="timepost">Temps de poste</MenuItem>
                  <MenuItem selected key="fuel" value="fuel">Consommation</MenuItem>
                </Select> </>)
              : ""}
            {
              cumulProd.length > 0 && selectedDevice === 'all' && selectedFilter === 'rotation' ?
                <>
                  Stock :
                  <Select value={selectedStock} onChange={handleSelectedStock}>
                    <MenuItem selected key="all" value="all">All</MenuItem>
                    {stock.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>

                  Materiau :
                  <Select value={selectedMaterial} onChange={handleSelectedMaterial}>
                    <MenuItem selected key="all" value="all">All</MenuItem>
                    {material.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </>
                : ""}
            {
              selectedDevice === 'all' ? (
                selectedFilter === 'all' ? (
                  <TableContainer className='tableContainer'>
                    <Table>
                      <TableHead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                        <TableRow>
                          <TableCell align={"center"}>Type Engin</TableCell>
                          <TableCell align={"center"}>Appareil</TableCell>
                          <TableCell align={"center"}>Temps de production </TableCell>
                          <TableCell align={"center"}>Total Rotation</TableCell>
                          <TableCell align={"center"}>Moyenne Cadence</TableCell>
                          <TableCell align={"center"}>Consommation</TableCell>
                          <TableCell align={"center"}>Temps de poste</TableCell>
                          <TableCell align={"center"}>Distance</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {cumulProd.sort((a, b) => {
                          const nameA = getNamebySerial(a.serial);
                          const nameB = getNamebySerial(b.serial);

                          if (nameA < nameB) return -1;
                          if (nameA > nameB) return 1;

                          if (a.device_type < b.device_type) return -1;
                          if (a.device_type > b.device_type) return 1;



                          return 0; // Si les types sont identiques
                        }).map((item, index) => {
                          const rotations = JSON.parse(item.rotation);
                          const totalTaskDuration = rotations.reduce((sum, rotation) => sum + rotation.task_duration, 0);

                          return (
                            <TableRow key={index} className="responsiveTableRow">
                              <TableCell className="responsiveTableCell" align="center">
                                <img
                                  src={require("Public/images/picto/" + getPictoByTypeDevice(item.device_type))}
                                  alt=""
                                  className="imgPictoDashboardProdCumul"
                                />
                              </TableCell>
                              <TableCell className="responsiveTableCell" align="center">
                                {getNamebySerial(item.serial)}
                              </TableCell>
                              <TableCell className="responsiveTableCell" align="center">
                                <Button onClick={() => handleOpen(rotations)}>
                                  {secondesEnDuree(totalTaskDuration)}
                                </Button>
                              </TableCell>
                              <TableCell className="responsiveTableCell" align="center">
                                {item.rotation_cnt}
                              </TableCell>
                              <TableCell className="responsiveTableCell" align="center">
                                {item.average_cadence.toFixed(2)}tr/h
                              </TableCell>
                              <TableCell className="responsiveTableCell" align="center">
                                {item.fuel} L
                              </TableCell>
                              <TableCell className="responsiveTableCell" align="center">
                                {secondesEnDuree(item.time_post)}
                              </TableCell>
                              <TableCell className="responsiveTableCell" align="center">
                                {distanceFormat(item.distance_traveled)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : selectedFilter === 'rotation' && (selectedStock === 'all' && selectedMaterial === 'all') ? (
                  <>
                    <Grid container spacing={2} justifyContent="center" style={{ marginTop: '1%' }}>
                      <Grid item>
                        <Card style={{ backgroundColor: 'White', color: 'Black', width: '250px', height: '200px', padding: '10px', borderRadius: '8px' }}>
                          <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="h6" gutterBottom align="center">
                              Total Rotation
                            </Typography>
                            <Typography variant="body1" gutterBottom align="center" style={{ fontWeight: 'bold' }}>
                              <br></br>
                              {dataFilter.totalRotations}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item>
                        <Card style={{ backgroundColor: 'White', color: 'Black', width: '100%', maxWidth: '600px', padding: '10px', borderRadius: '8px' }}>
                          <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <TableContainer component={Paper} >
                              <Typography variant="h6" gutterBottom align="center">
                                Cumul des Stocks et Matériaux
                              </Typography>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="center">Stock</TableCell>
                                    <TableCell align="center">Matériel</TableCell>
                                    <TableCell align="center">Durée Totale</TableCell>
                                    <TableCell align="center">Nombre de Rotations</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {dataFilter.stockCumul.map((item, index) => (
                                    <TableRow key={index}>
                                      <TableCell align="center">{item.stock}</TableCell>
                                      <TableCell align="center">{item.material}</TableCell>
                                      <TableCell align="center">{secondesEnDuree(item.totalDuration)}</TableCell>
                                      <TableCell align="center">{item.rotation_count}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={10} md={8}>
                        <Card style={{ backgroundColor: 'White', color: 'Black', padding: '20px', borderRadius: '8px' }}>
                          <CardContent>
                            <Typography variant="h6" gutterBottom align="center">
                              Top 10 des Rotations par Appareil
                            </Typography>
                            <ResponsiveContainer width="100%" height={400}>
                              <BarChart data={dataFilter.top10Rotations} layout="vertical">
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis type="number" />
                                <YAxis
                                  dataKey="serial"
                                  type="category"
                                  tickFormatter={getNamebySerial}// Afficher le nom dans YAxis
                                  width={150}
                                />
                                <Tooltip content={<CustomTooltipRotation />} /> {/* Personnalisation du Tooltip */}
                                <Bar dataKey="rotationCount" fill="#8884d8" />
                              </BarChart>
                            </ResponsiveContainer>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </>
                ) : selectedFilter === 'rotation' && (selectedStock !== 'all' || selectedMaterial !== "all") ? (
                  <>
                    <Grid container spacing={2} justifyContent="center" style={{ marginTop: '1%' }}>
                      <Grid item>
                        <Card style={{ backgroundColor: 'White', color: 'Black', width: '250px', height: '200px', padding: '10px', borderRadius: '8px' }}>
                          <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="h6" gutterBottom align="center">
                              Total Rotation
                            </Typography>
                            <Typography variant="body1" gutterBottom align="center" style={{ fontWeight: 'bold' }}>
                              <br></br>
                              {dataStockMaterial.total.totalRotation}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>

                      <Grid item>
                        <Card style={{ backgroundColor: 'White', color: 'Black', width: '250px', height: '200px', padding: '10px', borderRadius: '8px' }}>
                          <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="h6" gutterBottom align="center">
                              Total Durée
                            </Typography>
                            <Typography variant="body1" gutterBottom align="center" style={{ fontWeight: 'bold' }}>
                              <br></br>
                              {secondesEnDuree(dataStockMaterial.total.totalTaskDuration)}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>

                      <Grid item>
                        <Card style={{ backgroundColor: 'White', color: 'Black', width: '100%', maxWidth: '600px', padding: '10px', borderRadius: '8px' }}>
                          <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <TableContainer component={Paper} >
                              <Typography variant="h6" gutterBottom align="center">
                                <span style={{ marginRight: '10px' }}>
                                  {selectedStock !== "all" ? selectedStock : ""}
                                </span>
                                <span>
                                  {selectedMaterial !== "all" ? selectedMaterial : ""}
                                </span>
                              </Typography>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="center">Appareil</TableCell>
                                    <TableCell align="center">Durée Totale</TableCell>
                                    <TableCell align="center">Nombre de Rotations</TableCell>
                                    <TableCell align="center">Cadence</TableCell>
                                    {selectedStock === "all" ? <TableCell align="center">Stock</TableCell> : ""}
                                    {selectedMaterial === "all" ? <TableCell align="center">Matériau</TableCell> : ""}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {dataStockMaterial.filteredData.map((item, index) => (
                                    <TableRow key={index}>
                                      <TableCell align="center">{getNamebySerial(item.serial)}</TableCell>
                                      <TableCell align="center">{secondesEnDuree(item.task_duration)}</TableCell>
                                      <TableCell align="center">{item.rotation_count}</TableCell>
                                      <TableCell align="center">{(item.task_duration / 3600).toFixed(2) > 0.00 ? (item.rotation_count / (item.task_duration / 3600).toFixed(2)).toFixed(2) : 0} tr/h</TableCell>
                                      {selectedStock === "all" ? <TableCell align="center">{item.stock}</TableCell> : ""}
                                      {selectedMaterial === "all" ? <TableCell align="center">{item.material}</TableCell> : ""}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </>
                ) : selectedFilter === 'timepost' ? (
                  <>
                    <Grid container spacing={2} justifyContent="center" style={{ marginTop: '1%' }}>
                      <Grid item>
                        <Card style={{ backgroundColor: 'White', color: 'Black', width: '250px', height: '200px', padding: '10px', borderRadius: '8px' }}>
                          <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="h6" gutterBottom align="center">
                              Total Temps de Poste
                            </Typography>
                            <Typography variant="body1" gutterBottom align="center" style={{ fontWeight: 'bold' }}>
                              <br></br>
                              {secondesEnDuree(dataFilter.totalTimePost)}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={10} md={8}>
                        <Card style={{ backgroundColor: 'White', color: 'Black', padding: '20px', borderRadius: '8px' }}>
                          <CardContent>
                            <Typography variant="h6" gutterBottom align="center">
                              Top 10 des temps de poste par Appareil
                            </Typography>
                            <ResponsiveContainer width="100%" height={400}>
                              <BarChart data={dataFilter.top10TimePost} layout="vertical">
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis type="number" tickFormatter={secondesEnDuree} />
                                <YAxis
                                  dataKey="serial"
                                  type="category"
                                  tickFormatter={getNamebySerial}// Afficher le nom dans YAxis
                                  width={150}
                                />
                                <Tooltip content={<CustomTooltipTimePost />} /> {/* Personnalisation du Tooltip */}
                                <Bar dataKey="time_post" fill="#8884d8" />
                              </BarChart>
                            </ResponsiveContainer>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </>
                ) : selectedFilter === 'fuel' ? (
                  <>
                    <Grid container spacing={2} justifyContent="center" style={{ marginTop: '1%' }}>
                      <Grid item>
                        <Card style={{ backgroundColor: 'White', color: 'Black', width: '250px', height: '200px', padding: '10px', borderRadius: '8px' }}>
                          <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="h6" gutterBottom align="center">
                              Total Consommation
                            </Typography>
                            <Typography variant="body1" gutterBottom align="center" style={{ fontWeight: 'bold' }}>
                              <br></br>
                              {dataFilter.totalFuel} L
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={10} md={8}>
                        <Card style={{ backgroundColor: 'White', color: 'Black', padding: '20px', borderRadius: '8px' }}>
                          <CardContent>
                            <Typography variant="h6" gutterBottom align="center">
                              Top 10 des Consommations par Appareil
                            </Typography>
                            <ResponsiveContainer width="100%" height={400}>
                              <BarChart data={dataFilter.top10Fuel} layout="vertical">
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis type="number" />
                                <YAxis
                                  dataKey="serial"
                                  type="category"
                                  tickFormatter={getNamebySerial}// Afficher le nom dans YAxis
                                  width={150}
                                />
                                <Tooltip content={<CustomTooltipFuel />} /> {/* Personnalisation du Tooltip */}
                                <Bar dataKey="fuel" fill="#8884d8" />
                              </BarChart>
                            </ResponsiveContainer>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </>
                ) : (<></>)) : (<></>)}

            {selectedDevice !== 'all' ? (cumulProd.filter(item => item.serial === selectedDevice).map((item, index) => (
              <Grid container spacing={2} justifyContent="center" style={{ marginTop: '1%' }} key={index}>
                <Grid item>
                  <Card style={{ backgroundColor: 'White', color: 'Black', width: '250px', height: '200px', padding: '10px', borderRadius: '8px' }}>
                    <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Typography variant="h6" gutterBottom align="center">
                        Type de l'engin
                      </Typography>
                      <img
                        src={require("Public/images/picto/" + getPictoByTypeDevice(item.device_type))}
                        alt=""
                        className='imgPictoDashboardProdCumul'
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card style={{ backgroundColor: 'White', color: 'Black', width: '250px', height: '200px', padding: '10px', borderRadius: '8px' }}>
                    <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Typography variant="h6" gutterBottom align="center">
                        Nom de l'appareil
                      </Typography>
                      <Typography variant="body1" align="center" style={{ fontWeight: 'bold' }}>
                        {getNamebySerial(item.serial)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card style={{ backgroundColor: 'White', color: 'Black', width: '250px', height: '200px', padding: '10px', borderRadius: '8px' }}>
                    <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Typography variant="h6" gutterBottom align="center">
                        Nombre de rotation
                      </Typography>
                      <Typography variant="body1" align="center" style={{ fontWeight: 'bold' }}>
                        {item.rotation_cnt}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card style={{ backgroundColor: 'White', color: 'Black', width: '250px', height: '200px', padding: '10px', borderRadius: '8px' }}>
                    <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Typography variant="h6" gutterBottom align="center">
                        Temps de poste
                      </Typography>
                      <Typography variant="body1" align="center" style={{ fontWeight: 'bold' }}>
                        <br></br>
                        {secondesEnDuree(item.time_post)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card style={{ backgroundColor: 'White', color: 'Black', width: '250px', height: '200px', padding: '10px', borderRadius: '8px' }}>
                    <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Typography variant="h6" gutterBottom align="center">
                        Consommations
                      </Typography>
                      <Typography variant="body1" align="center" style={{ fontWeight: 'bold' }}>
                        <br></br>
                        {item.fuel} L
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card style={{ backgroundColor: 'White', color: 'Black', width: '250px', height: '200px', padding: '10px', borderRadius: '8px' }}>
                    <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Typography variant="h6" gutterBottom align="center">
                        Distances
                      </Typography>
                      <Typography variant="body1" align="center" style={{ fontWeight: 'bold' }}>
                        <br></br>
                        {distanceFormat(item.distance_traveled)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card style={{ backgroundColor: 'White', color: 'black', width: '100%', maxWidth: '600px', padding: '10px', borderRadius: '8px' }}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom align="center">
                        Comparaison Temps de Poste et Temps de Travail
                      </Typography>
                      <BarChart width={500} height={300} data={[{ name: 'Temps de Poste', duree: item.time_post },
                      { name: 'Temps de Travail', duree: JSON.parse(item.rotation).reduce((sum, rotation) => sum + rotation.task_duration, 0) }]}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis tickFormatter={(value) => secondesEnDuree(value)} tick={{ fontSize: 11 }} />
                        <Tooltip formatter={(value) => secondesEnDuree(value)} />
                        <Legend />
                        <Bar dataKey="duree" fill="#3498db" />
                      </BarChart>
                    </CardContent>
                  </Card>
                </Grid>
                {item.time_post_by_date.length > 0 ?
                  <Grid item>
                    <Card style={{ backgroundColor: 'White', color: 'black', width: '100%', maxWidth: '600px', padding: '10px', borderRadius: '8px' }}>
                      <CardContent>
                        <Typography variant="h6" gutterBottom align="center">
                          Temps de poste par jour
                        </Typography>
                        <BarChart width={500} height={300} data={fillMissingDates(item.time_post_by_date.sort((a, b) => new Date(a.ts) - new Date(b.ts)))}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="ts" tickFormatter={(ts) => {
                            const date = new Date(ts);
                            const options = { month: '2-digit', day: '2-digit' }; // Format MM-DD
                            return date.toLocaleDateString('fr-FR', options); // ou utilisez 'en-US' selon vos besoins
                          }} />
                          <YAxis tickFormatter={(value) => secondesEnDuree(value)} tick={{ fontSize: 11 }} />
                          <Tooltip formatter={(value) => secondesEnDuree(value)} />
                          <Legend />
                          <Bar dataKey="time_post" fill="#4b9e67" />
                        </BarChart>
                      </CardContent>
                    </Card>
                  </Grid> : ""}
                {item.cadence_by_date.length > 0 ?
                  <Grid item>
                    <Card style={{ backgroundColor: 'White', color: 'black', width: '100%', maxWidth: '600px', padding: '10px', borderRadius: '8px' }}>
                      <CardContent>
                        <Typography variant="h6" gutterBottom align="center">
                          Cadence par date
                        </Typography>
                        <BarChart width={500} height={300} data={fillMissingDates(item.cadence_by_date.sort((a, b) => new Date(a.ts) - new Date(b.ts)), true)}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="ts" tickFormatter={(ts) => {
                            const date = new Date(ts);
                            const options = { month: '2-digit', day: '2-digit' };
                            return date.toLocaleDateString('fr-FR', options);
                          }} />
                          <YAxis tickFormatter={(value) => value} tick={{ fontSize: 11 }} />
                          <Tooltip formatter={(value) => value} />
                          <Legend />
                          <Bar dataKey="cadence" fill="#f39c12" />
                        </BarChart>
                      </CardContent>
                    </Card>
                  </Grid> : ""}
                {JSON.parse(item.rotation).length > 0 ? <Grid item>
                  <Card style={{ backgroundColor: 'White', color: 'black', width: '100%', maxWidth: '600px', padding: '10px', borderRadius: '8px' }}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom align="center">
                        Rotations
                      </Typography>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align='center'>Stock</TableCell>
                              <TableCell align='center'>Material</TableCell>
                              <TableCell align='center'>Nombre de Rotation</TableCell>
                              <TableCell align='center'>Durée</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {JSON.parse(item.rotation).map((rotation, index) => (
                              <TableRow key={index}>
                                <TableCell align='center'>{rotation.stock}</TableCell>
                                <TableCell align='center'>{rotation.material}</TableCell>
                                <TableCell align='center'>{rotation.rotation_count}</TableCell>
                                <TableCell align='center'>{secondesEnDuree(rotation.task_duration)}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
                  : ""}
              </Grid>
            ))) : ""}
            <Modal open={open} onClose={handleClose}>
              <Box
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '80%',
                  maxWidth: 800,
                  backgroundColor: 'white',
                  padding: '16px',
                  boxShadow: 24,
                  overflow: 'auto'
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Rotation Details
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Stock</TableCell>
                      <TableCell>Materiau</TableCell>
                      <TableCell>Rotation</TableCell>
                      <TableCell>Durée</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedRotations.map((rotation, idx) => (
                      <TableRow key={idx}>
                        <TableCell>{rotation.stock}</TableCell>
                        <TableCell>{rotation.material}</TableCell>
                        <TableCell>{rotation.rotation_count}</TableCell>
                        <TableCell>{secondesEnDuree(rotation.task_duration)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Modal>
          </>

        )}
      </div>
    </div>
  )
}

export default DashboardProductionCumulative