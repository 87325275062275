import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import {
    Collapse,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import Button from "@mui/material/Button";
import fr from "date-fns/locale/fr";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faChevronDown, faChevronRight, faPlus, faRemove, faXmark, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import TextField from "@mui/material/TextField";
import * as XLSX from 'xlsx';
import "./ManageRotation.css"
import worksite from "../../admin/Worksite";
import { get } from "leaflet/src/dom/DomUtil";

var material_toshowInModal = 'nope';
var serial_toshowInModal = 'nope';
var stock_toshowInModal = 'nope';
var new_rotation_toshowInModal = 'nope';
var device_type_toshowInModal = 'nope';
const apiUrl = process.env.REACT_APP_API_URL;
let devicePeriod;
function ManageRotation() {
    const location = useLocation();
    const [selectedStopDate, setSelectedStopDate] = useState(new Date());
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [timestamp, setTimestamp] = useState(new Date());
    const [selectedWorksite, setSelectedWorksite] = useState("");
    const [selectedSerial, setSelectedSerial] = useState("");
    const [showModalAddRotation, setShowModalAddRotation] = useState(false);
    const [Worksite, setWorksite] = useState([]);
    const [Rotation, setRotation] = useState([]);
    const [deviceName, setDeviceName] = useState([]);
    const [extendedLines, setExtendedLines] = useState([]);
    const [extendedLinesRotation, setExtendedLinesRotation] = useState([]);
    const [selectedMaterial, setSelectedMaterial] = useState('');
    const [selectedStock, setSelectedStock] = useState('');
    const [materials, setMaterials] = useState([]);
    const [stocks, setStocks] = useState([]);
    const [showModalUpdateRotation, setShowModalUpdateRotation] = useState(false);
    const [selectedSerials, setSelectedSerials] = useState([]);
    const [serials, setSerials] = useState([]);
    const [oldStock, setOldStock] = useState('');
    const [oldMaterial, setOldMaterial] = useState('');
    const [newStock, setNewStock] = useState('');
    const [newMaterial, setNewMaterial] = useState('');

    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());

    const handleCheckboxChange = (event) => {
        const serial = event.target.name;
        if (event.target.checked) {
            setSelectedSerials([...selectedSerials, serial]);
        } else {
            setSelectedSerials(selectedSerials.filter((s) => s !== serial));
        }
    };

    const adjustTimeToToday = (time) => {
        const newTime = new Date(selectedStartDate);
        newTime.setHours(time.getHours());
        newTime.setMinutes(time.getMinutes());
        return newTime;
    };

    const handleStartTimeChange = (date) => {
        setStartTime(adjustTimeToToday(date));
    };

    const handleEndTimeChange = (date) => {
        setEndTime(adjustTimeToToday(date));
    };

    const handleOldStockChange = (event) => setOldStock(event.target.value);
    const handleOldMaterialChange = (event) => setOldMaterial(event.target.value);
    const handleNewStockChange = (event) => setNewStock(event.target.value);
    const handleNewMaterialChange = (event) => setNewMaterial(event.target.value);

    const cellStyle = {
        textAlign: 'center'
    };

    useEffect(() => {
        axios.get(apiUrl + `/api/user/worksite`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {

                const worksite = res.data.sort((a, b) => a.worksiteid - b.worksiteid);
                setWorksite(worksite);
            })
        if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
            axios.get(apiUrl + `/api/admin/device`, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then(res => {
                    setDeviceName(res.data.sort((a, b) => a.username.localeCompare(b.username)));
                })
        } else {
            axios.get(apiUrl + `/api/user/device`, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then(res => {
                    setDeviceName(res.data.sort((a, b) => a.username.localeCompare(b.username)));
                })
        }
    }, []);

    useEffect(() => {
        var start, stop;
        start = selectedStartDate;
        stop = selectedStopDate;
        if (location.state) {
            if (location.state.start) {
                start = location.state.start;
                stop = location.state.stop;
                setSelectedStopDate(stop);
                setSelectedStartDate(start);
                setStartTime(start);
                setEndTime(stop);

                const dateStart = new Date(location.state.start);
                const dateStop = new Date(location.state.stop);
                dateStart.setHours(0, 0, 1);
                start = new Date(dateStart);

                dateStop.setHours(23, 59, 59);
                stop = new Date(dateStop);

                if (location.state) {
                    if (location.state.worksite) {
                        setSelectedWorksite(location.state.worksite)
                    }
                }
            }
        } else {
            const dateStart = new Date(start);
            const dateStop = new Date(stop);
            dateStart.setHours(0, 0, 1);
            start = new Date(dateStart);

            dateStop.setHours(23, 59, 59);
            stop = new Date(dateStop);
        }


        if (selectedWorksite !== "") {

            axios.post(apiUrl + `/api/user/prodevent`, {
                startdate: start, stopdate: stop, selectedWorksite: selectedWorksite
            }, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then(res => {
                    setRotation(res.data)
                    setSerials(res.data.map(item => item.serial))
                })

            axios.post(apiUrl + `/api/user/worksitemateriauxstocks`, {
                selectedWorksite: selectedWorksite
            }, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then(res => {
                    const materiaux = res.data.materiaux
                        .replace(/[{}]/g, '')
                        .split(',')
                        .map(material => material.trim());

                    const stocks = res.data.stocks
                        .replace(/[{}]/g, '')
                        .split(',')
                        .map(stock => stock.trim());
                    setStocks(stocks);
                    setMaterials(materiaux)
                })
        }
    }, [location.state, selectedWorksite])


    const handleChangeMaterial = (event) => {
        setSelectedMaterial(event.target.value);
    };

    const handleChangeStock = (event) => {
        setSelectedStock(event.target.value);
    };

    function millisecondsToDecimalHours(milliseconds) {
        // Convertir les millisecondes en heures (1 seconde = 1000 millisecondes, 1 minute = 60 secondes)
        if (milliseconds !== undefined) {
            const hours = milliseconds / (1000 * 60 * 60);

            return hours.toFixed(2);
        } else
            return "0.00";
    }

    function getWorksiteName(idWorksite) {
        if (Worksite.length > 0) {
            for (let i = 0; i < Worksite.length; i++) {
                if (Worksite[i].worksiteid === idWorksite) {
                    return Worksite[i].worksitename;
                }
            }
        }
        return null;
    }
    const handleModalCloseAddRotation = () => {
        serial_toshowInModal = "nope";
        material_toshowInModal = "nope";
        stock_toshowInModal = "nope";
        device_type_toshowInModal = "nope";
        new_rotation_toshowInModal = "nope";

        setShowModalAddRotation(false);
    }

    const handleModalOpenAddRotation = (serial, material, stock, device_type, last_timestamp, new_rotation) => {
        if (serial !== undefined)
            serial_toshowInModal = serial;

        if (material !== undefined)
            material_toshowInModal = material;

        if (stock !== undefined)
            stock_toshowInModal = stock;

        if (device_type !== undefined)
            device_type_toshowInModal = device_type;

        if (new_rotation !== undefined)
            new_rotation_toshowInModal = new_rotation

        if (last_timestamp !== undefined)
            setTimestamp(last_timestamp);

        setShowModalAddRotation(true);
    }

    function confirmDeleteRotation(idRotation) {
        const shouldDelete = window.confirm("Êtes-vous sûr de vouloir supprimer cette rotation ? Cette action est irréversible.");
        if (shouldDelete) {
            if (idRotation !== "") {
                // Effectuer la suppression
                DeleteRotationById(idRotation);
            }
        }
    }

    function DeleteRotationById(idRotation) {
        axios.post(apiUrl + "/api/user/deleterotation", {
            idRotationMaterial: idRotation,
        }, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then((response) => {
                alert(response.data.message);
                window.location.reload(false);
            })
            .catch((error) => {
                console.error("Erreur lors de la suppression :", error);
                alert("Erreur lors de la suppression");
            });

    }


    function getTotalTransportedforSerial(serial, rotation) {
        const Device = deviceName.find(device => device.username === serial);
        if (Device)
            return (Device.payload * rotation) / 1000
    }

    function formatDateWithTime(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return <div><span>{day}/{month}/{year}<br />{hours}:{minutes}:{seconds}</span></div>;
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${day}/${month}/${year}`;
    }


    const addRotation = (e) => {
        e.preventDefault();

        let serial;
        if (serial_toshowInModal !== 'nope')
            serial = serial_toshowInModal
        else
            serial = e.target.elements.serial.value;

        const material = e.target.elements.material.value;
        const stock = e.target.elements.stock.value;
        const device_type = device_type_toshowInModal;

        let new_rotation = false;
        if (new_rotation_toshowInModal !== "nope")
            new_rotation = new_rotation_toshowInModal;


        axios.post(apiUrl + "/api/user/addrotation", {
            serial: serial,
            material_name: material,
            worksiteid: selectedWorksite,
            ts: timestamp.toISOString(),
            stock_name: stock,
            device_type,
            new_rotation
        }, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then((response) => {
                alert("Rotation crée avec succès")
                window.location.reload(false);
            })
            .catch((error) => {
                console.error("Erreur lors de l'ajout :", error);
                alert("Erreur lors de l'ajout");
            });
    }

    const handleChangeSerial = (event) => {
        setSelectedSerial(event.target.value);
    }


    const gestionToggleLigne = (index) => {
        const newLinesExtended = [...extendedLines];
        const indexActuel = newLinesExtended.indexOf(index);

        if (indexActuel === -1) {
            newLinesExtended.push(index);
        } else {
            newLinesExtended.splice(indexActuel, 1);
        }
        setExtendedLines(newLinesExtended);
    };

    const gestionToggleLigneRotation = (index) => {
        const newLinesExtendedRotation = [...extendedLinesRotation];
        const indexActuel = newLinesExtendedRotation.indexOf(index);

        if (indexActuel === -1) {
            newLinesExtendedRotation.push(index);
        } else {
            newLinesExtendedRotation.splice(indexActuel, 1);
        }

        setExtendedLinesRotation(newLinesExtendedRotation);
    };

    function getNamebySerial(serial) {

        if (deviceName.length > 0 && serial !== 'nope') {
            const device = deviceName.find(device => device.username === serial);
            let selectedDevice = "noname";
            let NameDevice = JSON.parse(device.name_device);
            let StartDate = selectedStartDate.setHours(0, 0, 1);
            if (NameDevice.length > 1) {

                for (const item of NameDevice) {
                    const itemDate = new Date(item.timestamp);
                    const startDate = new Date(StartDate);

                    if (itemDate < startDate) {
                        selectedDevice = item.nameDevice;
                    }
                }
            } else {
                const itemDate = new Date(NameDevice.timestamp);
                const startDate = new Date(StartDate);
                if (itemDate < startDate)
                    selectedDevice = NameDevice.nameDevice;
            }


            let str = selectedDevice;
            if (str.startsWith('noname'))
                str = str + '(' + serial + ')';
            return str;
        }
    }

    const generateExcelData = (data) => {
        const excelData = [];
        const groupedData = {};
        // Ajouter l'en-tête
        excelData.push(['Date', 'Engins', 'Stock', 'Matériaux', 'Temps par stock/matériaux', 'Nombre de tour par stock matériaux']);
        data.forEach((item) => {
            const device = getNamebySerial(item.serial);
            const serial = item.serial;
            for (const stockMateriauKey in item.materialStock) {

                const materiaux = stockMateriauKey.split('_')[0]; // Extraire le matériel de la clé du stock
                const stock = stockMateriauKey.split('_')[1];
                item.materialStock[stockMateriauKey].forEach((rotation) => {
                    const date = rotation.timestamp.substring(0, 10); // Extraire la date du timestamp
                    const key = `${stockMateriauKey}_${date}_${serial}`;
                    if (!groupedData[key]) {
                        groupedData[key] = {
                            date,
                            device,
                            stock,
                            materiaux,
                            time: 0,
                            nbrRotation: 0,
                        };
                    }
                    groupedData[key].time = millisecondsToDecimalHours(item.cumulativeTime[key]);

                    groupedData[key].nbrRotation = item.materialStockCountsDate[key];
                });
            }
        });
        const sortedData = Object.values(groupedData).sort((a, b) => new Date(a.date) - new Date(b.date));
        for (const key in sortedData) {
            const { date, device, stock, materiaux, time, nbrRotation } = sortedData[key];
            excelData.push([date, device, stock, materiaux, time, nbrRotation]);
        }

        return excelData;
    };

    const exportToExcel = (data) => {
        // Générer les données pour Excel
        const excelData = generateExcelData(data);

        // Créer un objet de feuille de calcul
        const ws = XLSX.utils.aoa_to_sheet(excelData);

        // Créer un objet de classeur
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Rotation');

        // Enregistrer le fichier
        XLSX.writeFile(wb, 'export.xlsx');
    };

    function formatTime(secondes) {
        if (secondes !== undefined) {
            const hours = Math.floor(secondes / 3600);
            const minutes = Math.floor((secondes % 3600) / 60);
            const seconds = secondes % 60;
            let time = `${String(hours).padStart(2, '0')}h${String(minutes).padStart(2, '0')}m${String(seconds).padStart(2, '0')}s`;
            return time;
        } else
            return "0s"
    }

    function getTimeTotalRotation(periods) {
        let totalTimeRotation = 0;

        if (periods.length > 0) {
            periods.forEach((period) => {
                totalTimeRotation += period.task_duration;
            });
        }
        return formatTime(totalTimeRotation);
    }

    const handleShowUpdateRotation = () => {
        setShowModalUpdateRotation(true);
    }

    const handleCloseModalUpdateRotation = () => {
        setShowModalUpdateRotation(false);
    }

    const updateRotation = () => {
        axios.post(apiUrl + "/api/user/updaterotation", {
            startDate: startTime.toISOString(),
            stopDate: endTime.toISOString(),
            selectedWorksite,
            serialNumbers: selectedSerials,
            oldMaterial,
            oldStock,
            newStock,
            newMaterial
        }, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then((response) => {
                alert(response.data);
                window.location.reload(false);
            })
            .catch((error) => {
                console.error("Erreur lors de l'update :", error);
                alert("Erreur lors de l'ajout");
            })
    };
    return (
        <div>
            <div style={{ display: "flex" }}>
                <p>Site : {getWorksiteName(selectedWorksite)}</p>
                <p style={{ marginLeft: "1%" }}>Start : {formatDate(selectedStartDate)}</p>
                <p style={{ marginLeft: "1%" }}>Stop : {formatDate(selectedStopDate)}</p>
                {selectedStartDate.toDateString() !== new Date(Date.now()).toDateString() ? <Button style={{ width: "10%", marginLeft: '1%' }} variant="contained" color="primary" onClick={handleShowUpdateRotation}>
                    Modification
                </Button> : ""}
            </div>

            <div className="rotation-container">
                {Rotation.length > 0 && Rotation.map((item, index) => (
                    item.total_rotcnt > 0 ? (
                        <div key={index} className="rotation-item">
                            <div className="rotation-summary">
                                <span>
                                    <FontAwesomeIcon
                                        icon={extendedLines.includes(index) ? faChevronDown : faChevronRight}
                                        onClick={() => gestionToggleLigne(index)}
                                    />
                                </span>
                                <span
                                    className='span'>{getNamebySerial(item.serial)} : {item.total_rotcnt} voyage{item.total_rotcnt !== 1 && 's'} au total en {getTimeTotalRotation(item.cumul_list)} ({getTotalTransportedforSerial(item.serial, item.total_rotcnt)} T)
                                    <Button title='addRotation' className='btnAddRotationManageRotation'
                                        onClick={() => handleModalOpenAddRotation(item.serial, undefined, undefined, item.device_type, new Date(item.last_stop_ts), true)}><FontAwesomeIcon icon={faPlus} color="green" size="lg" /></Button></span>
                            </div>
                            <Collapse in={extendedLines.includes(index)}>
                                <div className="extended-lines">
                                    {item.cumul_list.map((cumul, key) => {
                                        return (
                                            <div key={key} className="material-stock-item">
                                                <div className="materialStock">
                                                    <span>
                                                        <FontAwesomeIcon
                                                            icon={extendedLinesRotation.includes(key) ? faChevronDown : faChevronRight}
                                                            onClick={() => gestionToggleLigneRotation(key)}
                                                        />
                                                    </span>
                                                    <span className="spanCol">
                                                        {cumul.stock} - {cumul.material}
                                                    </span>
                                                    <span>
                                                        : {cumul.rotation_count} voyage{cumul.rotation_count !== 1 && 's'} en {formatTime(cumul.task_duration)} ({getTotalTransportedforSerial(item.serial, cumul.rotation_count)} T)
                                                    </span>
                                                    <Button
                                                        className="btnAddRotationManageRotation"
                                                        onClick={() => handleModalOpenAddRotation(item.serial, cumul.material, cumul.stock, item.device_type, new Date(cumul.events[cumul.events.length - 1].timestamp), false)}>
                                                        <FontAwesomeIcon icon={faPlus} color="green" />
                                                    </Button>
                                                </div>
                                                <Collapse in={extendedLinesRotation.includes(key)}>
                                                    <div className="rotation-history">
                                                        {cumul.events.map((rotation, indexRotation) => (
                                                            <div key={indexRotation} className="rotation-entry">
                                                                <span>{formatDateWithTime(rotation.timestamp)}</span>
                                                                <Button className="btnAddRotationManageRotation"
                                                                    onClick={() => confirmDeleteRotation(rotation.id)}>
                                                                    <FontAwesomeIcon icon={faRemove} color="red" />
                                                                </Button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Collapse>
                                            </div>
                                        );
                                    })}
                                </div>
                            </Collapse>
                        </div>
                    ) : ""
                ))}
            </div>

            <Modal size="lg" show={showModalAddRotation} onHide={handleModalCloseAddRotation}>
                <Modal.Header closeButton>
                    <Modal.Title> Add Rotation </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <form onSubmit={addRotation}>
                            <div>
                                {serial_toshowInModal !== "nope" ?
                                    <TextField
                                        id="serial"
                                        name="serial"
                                        label="Appareil"
                                        value={getNamebySerial(serial_toshowInModal)}
                                        fullWidth
                                        readOnly
                                    />
                                    :
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel id="deviceId-label">Appareil:</InputLabel>
                                        <Select name="serial" id="serial"
                                            labelId="serial-label"
                                            value={selectedSerial}
                                            onChange={handleChangeSerial}
                                        >

                                            <MenuItem value="">Sélectionnez un appareil</MenuItem>
                                            {deviceName.map((serial, index) => (
                                                <MenuItem key={index}
                                                    value={serial.username}>{getNamebySerial(serial.username)}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                }
                            </div>
                            <div>
                                {material_toshowInModal !== "nope" ?
                                    <TextField
                                        value={material_toshowInModal}
                                        label="Material"
                                        variant="outlined"
                                        name="material"
                                        id="material"
                                        fullWidth
                                        margin="normal"
                                        readOnly
                                    /> :
                                    <FormControl sx={{ width: "100%", marginTop: '1%' }}>
                                        <InputLabel id="material-label">Matériaux:</InputLabel>
                                        <Select
                                            labelId="material-label"
                                            id="material"
                                            name="material"
                                            value={selectedMaterial}
                                            onChange={handleChangeMaterial}
                                            label="Matériaux"
                                            selected
                                        >
                                            <MenuItem value="">Sélectionnez un matériau</MenuItem>
                                            {materials.map((material, index) => (
                                                <MenuItem key={index} value={material}>{material}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                }
                                {stock_toshowInModal !== "nope" ?
                                    <TextField
                                        value={stock_toshowInModal}
                                        label="Stock"
                                        variant="outlined"
                                        name="stock"
                                        id="stock"
                                        fullWidth
                                        margin="normal"
                                        readOnly
                                    /> :
                                    <FormControl sx={{ width: "100%", marginTop: '1%' }}>
                                        <InputLabel id="material-label">Stocks:</InputLabel>
                                        <Select
                                            labelId="stock-label"
                                            id="stock"
                                            name="stock"
                                            value={selectedStock}
                                            onChange={handleChangeStock}
                                            label="Stock"
                                            selected
                                        >
                                            <MenuItem value="">Sélectionnez un stock</MenuItem>
                                            {stocks.map((stock, index) => (
                                                <MenuItem key={index} value={stock}>{stock}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                }

                            </div>
                            <br />
                            <div>
                                <label>Date : </label>
                                <DatePicker
                                    locale={fr}
                                    selected={timestamp}
                                    onChange={(date) => setTimestamp(date)}
                                    timeInputLabel="Time:"
                                    dateFormat="dd/MM/yyyy h:mm aa"
                                    showTimeInput
                                    readOnly
                                />
                            </div>
                            <br />
                            <Button type="submit" variant="contained" color="primary"
                                style={{ width: '100%', alignSelf: 'center' }}>
                                Ajouter la rotation
                            </Button>
                        </form>
                    </>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" show={showModalUpdateRotation} onHide={handleCloseModalUpdateRotation}>
                <Modal.Header closeButton>
                    <Modal.Title>Modification rotation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <FormGroup style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            {serials.map((serial) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name={serial}
                                            onChange={handleCheckboxChange}
                                        />
                                    }
                                    label={getNamebySerial(serial)}
                                    key={serial}
                                />
                            ))}
                        </FormGroup>
                        <FormControl fullWidth style={{ marginTop: 16 }}>
                            <InputLabel>Ancien Stock</InputLabel>
                            <Select value={oldStock} onChange={handleOldStockChange}>
                                {stocks.map((stock) => (
                                    <MenuItem key={stock} value={stock}>
                                        {stock}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth style={{ marginTop: 16 }}>
                            <InputLabel>Ancien Matériau</InputLabel>
                            <Select value={oldMaterial} onChange={handleOldMaterialChange}>
                                {materials.map((material) => (
                                    <MenuItem key={material} value={material}>
                                        {material}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/* Combobox pour sélectionner les nouveaux stock/matériau */}
                        <FormControl fullWidth style={{ marginTop: 16 }}>
                            <InputLabel>Nouveau Stock</InputLabel>
                            <Select value={newStock} onChange={handleNewStockChange}>
                                {stocks.map((stock) => (
                                    <MenuItem key={stock} value={stock}>
                                        {stock}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth style={{ marginTop: 16 }}>
                            <InputLabel>Nouveau Matériau</InputLabel>
                            <Select value={newMaterial} onChange={handleNewMaterialChange}>
                                {materials.map((material) => (
                                    <MenuItem key={material} value={material}>
                                        {material}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div style={{ marginTop: 16, marginBottom: '16px' }}>
                            <label>Start :</label>
                            <DatePicker
                                locale={fr}
                                selected={startTime}
                                onChange={handleStartTimeChange}
                                showTimeSelect
                                showTimeInput
                                timeInputLabel="Heure :"
                                dateFormat="dd/MM/yyyy HH:mm"
                                timeFormat="HH:mm"
                                timeIntervals={15} // Intervalles de 15 minutes
                            />
                        </div>

                        <div style={{ marginBottom: '16px' }}>
                            <label>Stop :</label>
                            <DatePicker
                                locale={fr}
                                selected={endTime}
                                onChange={handleEndTimeChange}
                                showTimeSelect
                                showTimeInput
                                timeInputLabel="Heure :"
                                dateFormat="dd/MM/yyyy HH:mm"
                                timeFormat="HH:mm"
                                timeIntervals={15} // Intervalles de 15 minutes
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" color="primary" onClick={updateRotation}>
                        Mettre à jour
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default ManageRotation;