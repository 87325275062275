import './DangersFixes.css';
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Toast } from "react-bootstrap";
import TextField, { Button } from "@mui/material";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import { Link } from "react-router-dom";
import { relativeTimeRounding } from "moment";
import { MapContainer, TileLayer, FeatureGroup, useMap, Circle, Polygon, Polyline } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import L from "leaflet";
import "leaflet-editable";

const apiUrl = process.env.REACT_APP_API_URL;

function DangersFixes() {
    const worksite = decodeURIComponent(window.location.href.split('/').pop());
    const [selectedWorksite, setSelectedWorksite] = useState();
    const [worksiteByClient, setWorksiteByClient] = useState([]);
    const [worksitePosition, setWorksitePosition] = useState({ lat: '', long: '', rayon: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDanger, setSelectedDanger] = useState('');
    const [drawnShapes, setDrawnShapes] = useState([]);
    const [DBShapes, setDBShapes] = useState([]);
    const leafletRef = useRef();
    const [drawHandler, setDrawHandler] = useState(null);
    const mapRef = useRef();
    const [zoomLevel, setZoomLevel] = useState(6);
    const [mapLayers, setMapLayers] = useState([]);
    const [isDrawing, setIsDrawing] = useState(false);
    const [selectedShape, setSelectedShape] = useState(null);
    const [showPolygons, setShowPolygons] = useState(false);
    const [shapeCategory, setShapeCategory] = useState('polyline');
    const [isEditing, setIsEditing] = useState(false);
    // const [isChecked, setIsChecked] = useState(false);
    // const markerRefs = useRef({});
    // const userid = sessionStorage.getItem("userId")

    const transformDrawnShapes = (drawnShapes) => {
        const polylines = [];
        const polygons = [];
        const circles = [];
        drawnShapes.forEach((shape) => {
            if (shape.type === 'polyline') {
                polylines.push({
                    id: shape.myId,
                    type_shape: shape.type,
                    coordinates_list: shape.points.map(point => ({
                        lat: point.lat,
                        lng: point.lng
                    })),
                    nb_points: shape.points.length,
                    type_danger: 1,
                    distance_annonce: 100,
                    altitude: 500,
                });
            } else if (shape.type === 'polygon') {
                polygons.push({
                    id: shape.myId,
                    type_shape: shape.type,
                    coordinates_list: shape.points.map(point => ({
                        lat: point.lat,
                        lng: point.lng
                    })),
                    nb_points: shape.points.length,
                    type_danger: 1,
                    distance_annonce: 100,
                    altitude: 500,
                });
            } else if (shape.type === 'circle') {
                circles.push({
                    id: shape.myId,
                    type_shape: shape.type,
                    coordinates_list: [{ lat: shape.center[0], lng: shape.center[1] }],
                    nb_points: shape.radius > 0 ? shape.radius : 0,
                    type_danger: shape.radius > 0 ? 2 : 3,
                    distance_annonce: 100,
                    altitude: 1000,
                });
            }
        });

        return { polylines, polygons, circles };
    }

    const handleSendDrawnShapes = async (drawnShapes) => {
        const { polylines, polygons, circles } = transformDrawnShapes(drawnShapes);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/worksitedangersfixes`, {
                worksite_id: selectedWorksite.worksiteid,
                polyline: JSON.stringify(polylines),  // Envoyer les polylines en tant que JSON string
                polygon: JSON.stringify(polygons),    // Envoyer les polygons en tant que JSON string
                circle: JSON.stringify(circles),      // Envoyer les circles en tant que JSON string
            },
                {
                    headers: { 'x-access-token': sessionStorage.getItem("token") }
                });

            // console.log("Response from API: ", response.data);
        } catch (error) {
            console.error("Error sending drawn shapes: ", error);
        }
    };

    useEffect(() => {
        if (drawnShapes.length > 0) {
            // const preparedData = transformDrawnShapes(drawnShapes);
            console.log("drawnShapes: ", drawnShapes);
            // console.log("transformDrawnShapes: ", preparedData);

            // if (selectedWorksite) {
            //     sendDrawnShapesToApi(transformDrawnShapes, selectedWorksite.worksiteid);
            // }

            // console.log("shapes: ", drawnShapes);
            // drawShapes est un tableau de shapes contenant un objet
            /* Par exemple ici côté broker, on attend : 
                public class DangerFixePolyline
                {
                    public List<Coordinates> coordinates_list = new List<Coordinates>();
                    public byte         nb_points         { get; set; }
                    public byte         type_danger         { get; set; }
                    public byte         distance_annonce    { get; set; }
                    public UInt16       altitude            { get; set; }
                }
                Il faudra donc ajouter : type_danger, distance_annonce, altitude
                Polyline : Obstacle
                Polygon / Circle : Zone d'inclusion, Zone d'exclusion 
                (voir   distance_annonce == 0 => zone d'inclusion
                        distance_annonce > 0 => zone d'exclusion)
            */
        }
    }, [drawnShapes, selectedWorksite]);

    // fetch worksitebyclient
    useEffect(() => {
        setIsLoading(true);
        axios.get(apiUrl + `/api/user/worksitebyclient`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                const worksitebyclient = res.data;
                setWorksiteByClient(worksitebyclient);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching worksite data:", error);
                setIsLoading(false);
            });
    }, []);

    // wait for worksiteByClientState
    useEffect(() => {
        getWorksite(worksiteByClient);
    }, [worksiteByClient]);

    const getWorksite = (data) => {
        const foundWorksite = data.find(w => w.worksitename === worksite);
        setSelectedWorksite(foundWorksite);  // Mise à jour de l'état
    }

    // Worksite determination
    useEffect(() => {
        if (selectedWorksite) {
            const position = JSON.parse(selectedWorksite.positionref);
            setWorksitePosition({
                lat: parseFloat(position.lat),
                long: parseFloat(position.long),
                rayon: selectedWorksite.rayon
            });
        }
    }, [selectedWorksite]);

    // Fetching and initializing drawnShapes from the database
    useEffect(() => {
        const fetchShapes = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/user/worksitedangersfixes/${selectedWorksite.worksiteid}`, {
                    headers: { 'x-access-token': sessionStorage.getItem("token") }
                });
                const { circle, polygon, polyline } = response.data.data;

                const parsedCircles = JSON.parse(circle);
                const parsedPolygons = JSON.parse(polygon);
                const parsedPolylines = JSON.parse(polyline);

                // Transformer les formes pour correspondre à celles créées dynamiquement
                const transformedCircles = parsedCircles.map(shape => ({
                    myId: shape.id,
                    type: 'circle',
                    center: [shape.coordinates_list[0].lat, shape.coordinates_list[0].lng],
                    radius: shape.nb_points, // radius stored in nb_points for circles
                    color: 'yellow'
                }));

                const transformedPolygons = parsedPolygons.map(shape => ({
                    myId: shape.id,
                    type: 'polygon',
                    points: shape.coordinates_list.map(point => ({
                        lat: point.lat,
                        lng: point.lng
                    })),
                    color: 'red'
                }));

                const transformedPolylines = parsedPolylines.map(shape => ({
                    myId: shape.id,
                    type: 'polyline',
                    points: shape.coordinates_list.map(point => ({
                        lat: point.lat,
                        lng: point.lng
                    })),
                    color: 'orange'
                }));

                const allShapes = [...transformedCircles, ...transformedPolygons, ...transformedPolylines];

                // Ajouter les formes à la carte via la fonction addShapeToMap
                allShapes.forEach(shape => {
                    addShapeToMap(shape, mapRef.current);
                });

                // Stocker les formes uniquement sous forme de JSON
                // setDBShapes(allShapes);
                setDrawnShapes(allShapes);

            } catch (error) {
                console.error("Error fetching shapes: ", error);
            }
        };

        if (selectedWorksite) {
            fetchShapes();
        }
    }, [selectedWorksite]);

    const addShapeToMap = (shape, map) => {
        let layer = null;

        if (shape.type === 'circle') {
            layer = L.circle(shape.center, {
                radius: shape.radius,
                color: shape.color,
                weight: 2,
            });
        } else if (shape.type === 'polygon') {
            layer = L.polygon(shape.points, {
                color: shape.color,
                weight: 2,
            });
        } else if (shape.type === 'polyline') {
            layer = L.polyline(shape.points, {
                color: shape.color,
                weight: 2,
            });
        }

        // Assigner l'ID personnalisé à la couche
        if (layer) {
            layer.options.myId = shape.myId;
            map.addLayer(layer);
        }

        return layer;
    };

    // useEffect(() => {
    //     if (DBShapes.length > 0 && drawnShapes.length > 0) {
    //         // compareShapes(DBShapes, drawnShapes);
    //         // console.log("layer", layer)
    //     }
    // }, [DBShapes, drawnShapes])

    // Clear editing by clicking on map
    useEffect(() => {
        if (mapRef.current) {
            const map = mapRef.current;
            const onMapClick = (e) => {
                if (selectedShape) {
                    // console.log("Selected shape ID:", selectedShape ? selectedShape.options.myId : "none");
                    // Check if the selected shape has a getBounds method (e.g., Polygon, Circle)
                    if (typeof selectedShape.getBounds === 'function') {
                        // Check if the click is outside the shape's bounds
                        if (!selectedShape.getBounds().contains(e.latlng)) {
                            if (isEditing) {
                                // console.log("cancel from useEffect");
                                handleCancelEditing(selectedShape);
                            }
                        }
                    }
                    else {
                        console.error('Shape does not support bounds checking:', selectedShape);
                    }
                }
            };

            // Add map click listener
            map.once('click', onMapClick);
            return () => {
                // Clean up the listener
                map.off('click', onMapClick);
            };
        }
    }, [selectedShape, mapRef, isEditing]);

    // useEffect(() => {
    //     console.log("________________________");
    //     console.log("isEditing: ", isEditing);
    //     console.log("isDrawing: ", isDrawing);
    //     console.log("isLoading: ", isLoading);
    //     console.log("________________________");
    // }, [isEditing, isDrawing, isLoading]);

    const handleCancelDrawing = () => {
        if (drawHandler) {
            drawHandler.disable(); // Désactiver l'outil de dessin actif
            setDrawHandler(null); // Réinitialiser le gestionnaire
            // leafletRef.current.leafletElement.off(L.Draw.Event.CREATED); // Supprimer l'événement
        }
        if (isEditing) {
            handleCancelEditing(selectedShape);
        }
        setIsDrawing(false); // Sortir du mode dessin
    };

    const enableEditingOnShape = (layer, id) => {
        // console.log(`Enable editing for shape with ID: ${id}`);
        const myId = id;
        const previousShape = selectedShape;
        // layer = normalizeShape(layer);

        if (previousShape && previousShape.myId === myId) {
            // La forme est déjà sélectionnée, inutile de refaire la sélection
            return;
        }

        setSelectedShape(layer);
        if (!layer || !id) {
            console.error("Layer or ID is invalid:", layer, id);
            return;
        }

        // Vérifier si les événements d'édition sont correctement attachés
        if (!layer._events || !layer._events.edit) {
            // console.log("Attaching edit event to the shape with ID:", id);
            layer.on('edit', () => handleEdit(layer, id)); // Attacher l'événement edit
        }


        if (previousShape && previousShape !== layer) {
            // console.log(`Deselecting previous shape with ID: ${previousShape.myId}`);
            handleCancelEditing(previousShape);
            // if (typeof previousShape.setStyle === 'function') {
            //     previousShape.setStyle({
            //         weight: 1,
            //         opacity: 1,
            //         clickable: true,
            //         interactive: true,
            //     });
            // }
            // Utilisez setTimeout pour permettre à la désélection de se terminer avant de sélectionner une nouvelle forme
            setTimeout(() => {
                activateEditing(layer, myId);
            }, 50);  // Le délai de 50ms peut être ajusté si nécessaire
        } else {
            activateEditing(layer, myId);
        }
    };

    // Fonction pour activer l'édition sur une forme
    const activateEditing = (layer, myId) => {
        setSelectedShape(layer);

        if (layer && layer.editing && typeof layer.editing.enable === 'function') {
            setIsEditing(true);
            try {
                layer.setStyle({
                    weight: 3,
                    opacity: 1,
                    clickable: true,
                    interactive: true,
                });

                // Réactiver l'édition après une petite pause pour forcer la mise à jour
                setTimeout(() => {
                    layer.editing.enable();
                    layer.bringToFront();

                    // Supprimer les anciens gestionnaires d'événements pour éviter les conflits
                    layer.off('edit');

                    // Ajouter un événement pour gérer l'édition
                    layer.on('edit', () => {
                        // console.log(`Shape with ID ${myId} edited:`, layer);
                        updateShapeInState(layer, myId);
                    });
                }, 100); // Attendre 100ms avant de réactiver l'édition
            } catch (error) {
                console.error("Failed to enable editing on layer: ", error);
            }
        }
    };

    const updateShapeInState = (layer, id) => {
        setDrawnShapes((prevShapes) => {
            return prevShapes.map((shape) => {
                if (shape.myId === id) {
                    // For circles
                    if (shape.type === 'circle') {
                        const { lat, lng } = layer.getLatLng();
                        const radius = layer.getRadius();
                        return { ...shape, center: [lat, lng], radius: radius };
                    }

                    // For polygons and polylines
                    if (shape.type === 'polygon' || shape.type === 'polyline') {
                        const latlngs = layer.getLatLngs();

                        // Check if latlngs is nested (polygon case)
                        let points = [];
                        if (Array.isArray(latlngs) && Array.isArray(latlngs[0])) {
                            // Handle nested arrays for polygons (rings)
                            points = latlngs[0].map((point) => ({
                                lat: point.lat,
                                lng: point.lng
                            }));
                        } else {
                            // Handle flat arrays for polylines
                            points = latlngs.map((point) => ({
                                lat: point.lat,
                                lng: point.lng
                            }));
                        }

                        // Update the shape with new points
                        return { ...shape, points };
                    }
                }
                return shape; // Keep other shapes unchanged
            });
        });
    };

    const compareShapes = (shapeA, shapeB) => {
        console.log("Comparing shapes:", shapeA.myId, shapeB.myId);
        console.log("Shape A:", shapeA);
        console.log("Shape B:", shapeB);

        const keysA = Object.keys(shapeA);
        const keysB = Object.keys(shapeB);
        const differences = {};

        keysA.forEach(key => {
            if (shapeA[key] !== shapeB[key]) {
                differences[key] = {
                    shapeA: shapeA[key],
                    shapeB: shapeB[key]
                };
            }
        });

        keysB.forEach(key => {
            if (!keysA.includes(key)) {
                differences[key] = {
                    shapeA: undefined,
                    shapeB: shapeB[key]
                };
            }
        });
        console.log("Differences:", differences);
    };

    const normalizeShape = (shape) => {
        if (!shape.myId && shape.options.myId) {
            shape.myId = shape.options.myId;
        }
        if (!shape.options.myId && shape.myId) {
            shape.options.myId = shape.myId;
        }
        return shape;
    }

    // Fonction pour gérer les modifications des formes (polylines, polygons, circles)
    const handleEdit = (layer, id) => {
        // console.log(`Shape with ID ${id} edited: `, layer);

        // On vérifie quel type de forme est en cours d'édition
        if (layer instanceof L.Circle) {
            const { lat, lng } = layer.getLatLng();
            const radius = layer.getRadius();

            // console.log(`Updated circle with new center (${lat}, ${lng}) and radius: ${radius}`);

            // Mise à jour des données de l'état des formes dessinées
            setDrawnShapes((prevShapes) =>
                prevShapes.map((shape) =>
                    shape.myId === id
                        ? { ...shape, center: [lat, lng], radius }
                        : shape
                )
            );
        }
        else if (layer instanceof L.Polygon || layer instanceof L.Polyline) {
            const latlngs = layer.getLatLngs();

            // Pour les polygones, les latlngs sont souvent imbriqués dans un tableau
            let points = [];
            if (Array.isArray(latlngs[0])) {
                points = latlngs[0].map((point) => ({
                    lat: point.lat,
                    lng: point.lng
                }));
            } else {
                points = latlngs.map((point) => ({
                    lat: point.lat,
                    lng: point.lng
                }));
            }

            // console.log(`Updated shape with ID ${id} and new points: `, points);

            // Mise à jour de l'état des formes dessinées avec les nouvelles coordonnées
            setDrawnShapes((prevShapes) =>
                prevShapes.map((shape) =>
                    shape.myId === id
                        ? { ...shape, points: [...points] }
                        : shape
                )
            );
        }
    };

    const handleCancelEditing = (shape) => {
        if (shape && isEditing) {
            // console.log(`Cancel editing for shape with ID: ${shape.myId}`);
            shape.editing.disable();  // Disable editing on the shape
            shape.bringToBack();
            shape.off('edit');
            setIsEditing(false);  // Exit editing mode
            setSelectedShape(null);  // Clear selected shape
        }
        if (!shape.options.myId) {
            console.error("Shape has no options.custom ID (myId):", shape);
        } 
        else {
            console.log("Editing cancelled for shape with options.ID:", shape.options.myId);
        }

        // if (!shape.myId) {
        //     console.error("Shape has no custom ID (myId):", shape);
        // } else {
        //     console.log("Editing cancelled for shape with ID:", shape.myId);
        // }
    };

    // Select shape from side list
    const handleShapeSelectionFromList = (shape) => {
        // Find the Leaflet layer using the custom myId  
        // console.log("shape:", shape);
        // console.log("mapref.current._layers:", mapRef.current._layers);
        const leafletShape = Object.values(mapRef.current._layers).find(layer => layer.options.myId === shape.myId);
        // console.log("leafletShape", leafletShape);
        if (leafletShape) {

            if (selectedShape && selectedShape !== leafletShape) {
                handleCancelEditing(selectedShape);
            }
            if (leafletShape.options.myId) {
                // console.log("Found shape with options.custom ID:", leafletShape.options.myId);
                enableEditingOnShape(leafletShape, shape.myId);
            }
            else if (leafletShape.myId) {
                // console.log("Found shape with custom ID:", leafletShape.myId);
                enableEditingOnShape(leafletShape, shape.myId);
            }

        } else {
            console.error("Shape not found with custom ID:", shape.options.myId);
        }
    };

    const handleDeleteShape = () => {
        if (selectedShape && mapRef.current) {
            const myId = selectedShape.options.myId;

            // console.log("Attempting to delete shape with myId:", myId);

            if (mapRef.current.hasLayer(selectedShape)) {
                mapRef.current.removeLayer(selectedShape);
                // console.log(`Shape with myId ${myId} removed from map.`);
            } else {
                console.error("Shape is not found on the map.");
            }
            setDrawnShapes((prevShapes) => {
                const updatedShapes = prevShapes.filter((shape) => shape.myId !== myId);
                // console.log("Updated drawnShapes:", updatedShapes);

                return updatedShapes;
            });
            handleCancelEditing(selectedShape);
            setSelectedShape(null);
        } else {
            console.error("No shape selected or map reference is invalid.");
        }
    };



    // Function to check if the click is outside the shape
    const checkIfClickOutsideShape = (latlng, shape, map) => {
        if (typeof shape.getBounds === 'function') {
            return !shape.getBounds().contains(latlng);  // Check bounds for polygons and circles
        } else if (shape instanceof L.Polyline) {
            // Custom check for polyline since it doesn't have bounds
            const latlngs = shape.getLatLngs();
            return !latlngs.some((point) => map.latLngToLayerPoint(point).distanceTo(map.latLngToLayerPoint(latlng)) < 10);
        }
        return true;  // If shape type not recognized, assume click is outside
    };

    const DrawShape = () => {
        const map = useMap();

        useEffect(() => {
            if (isDrawing && selectedDanger) {
                let handler;

                if (selectedDanger === "Circle") {
                    handler = new L.Draw.Circle(map, {
                        shapeOptions: {
                            color: 'yellow',
                            fillColor: 'yellow',
                            weight: 2,
                        }
                    });
                } else if (selectedDanger === "Polygon") {
                    handler = new L.Draw.Polygon(map, {
                        shapeOptions: {
                            color: 'red',
                            weight: 2,
                        }
                    });
                } else if (selectedDanger === "Polyline") {
                    handler = new L.Draw.Polyline(map, {
                        shapeOptions: {
                            color: 'orange',
                            weight: 2,
                        }
                    });
                }

                if (handler) {
                    handler.enable(); // Activer l'outil de dessin
                    if (!drawHandler) {
                        setDrawHandler(handler); // Stocker le gestionnaire pour pouvoir l'annuler plus tard
                    }

                    // Gérer la création de forme
                    const handleShapeCreated = (e) => {
                        const { layerType, layer } = e;
                        map.addLayer(layer);
                        const myId = drawnShapes.length ? Math.max(...drawnShapes.map(s => s.myId)) + 1 : 1;
                        layer.options.myId = myId;

                        if (layerType === "circle") {
                            const { lat, lng } = layer.getLatLng();
                            const radius = layer.getRadius();
                            const newCircle = {
                                myId, // Assign my custom ID
                                type: 'circle',
                                center: [lat, lng],
                                radius: radius,
                                color: 'yellow'
                            };
                            setDrawnShapes((prevShapes) => [...prevShapes, newCircle]);
                        } else if (layerType === "polygon") {
                            const latlngs = layer.getLatLngs()[0].map((point) => ({
                                lat: point.lat,
                                lng: point.lng,
                            }));
                            const newPolygon = {
                                myId, // Assign my custom ID
                                type: 'polygon',
                                points: latlngs,
                                color: 'red'
                            };
                            setDrawnShapes((prevShapes) => [...prevShapes, newPolygon]);
                        } else if (layerType === "polyline") {
                            const latlngs = layer.getLatLngs().map((point) => ({
                                lat: point.lat,
                                lng: point.lng,
                            }));
                            const newPolyline = {
                                myId, // Assign my custom ID
                                type: 'polyline',
                                points: latlngs,
                                color: 'orange'
                            };
                            setDrawnShapes((prevShapes) => [...prevShapes, newPolyline]);
                        }

                        // Disable drawing mode
                        handler.disable();
                        setIsDrawing(false);
                        setSelectedDanger('');
                    };

                    // Ajouter l'événement de création
                    map.once(L.Draw.Event.CREATED, handleShapeCreated); // Utiliser `once` pour ne pas réécouter plusieurs fois

                    // Nettoyage : Retirer l'événement lorsque le mode dessin est terminé
                    return () => {
                        map.off(L.Draw.Event.CREATED, handleShapeCreated); // Nettoyer l'événement après désactivation
                        if (handler) {
                            handler.disable(); // Désactiver l'outil de dessin si actif
                        }
                    };
                }
            }
        }, [isDrawing, selectedDanger, map]);

        return null;
    };

    return (
        <div className="flex">
            <h1>Dangers Fixes</h1>
            <h2>({worksite}, {worksitePosition.lat}, {worksitePosition.long})</h2>
            <Button onClick={() => handleSendDrawnShapes(drawnShapes)}>TEST SEND API</Button>
            {!isLoading && (
                <>
                    <div className="grid grid-cols-2 gap-2">
                        <div className='divFleet' style={{ marginTop: '2rem', maxHeight: '700px' }}>
                            <MapContainer
                                center={[worksitePosition.lat || 46.922407, worksitePosition.long || 2.670694]}
                                zoom={zoomLevel}
                                scrollWheelZoom={true}
                                ref={mapRef}
                                className='mapContainer'
                            >
                                <ChangeMapView coords={worksitePosition} />
                                <TileLayer
                                    url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                                    maxZoom={22}
                                    subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                                />
                                <FeatureGroup>
                                    <DrawShape />
                                    {drawnShapes.map((shape, idx) => {
                                        if (shape.type === 'circle') {
                                            return (
                                                <Circle
                                                    key={idx}
                                                    center={shape.center}
                                                    radius={shape.radius}
                                                    pathOptions={{ color: shape.color }}
                                                    myId={shape.myId}
                                                    eventHandlers={{
                                                        click: (e) => {
                                                            const leafletShape = Object.values(mapRef.current._layers).find(layer => layer.options.myId === shape.myId);
                                                            enableEditingOnShape(leafletShape, shape.myId);
                                                        }
                                                    }}
                                                />
                                            );
                                        } else if (shape.type === 'polygon') {
                                            return (
                                                <Polygon
                                                    key={idx}
                                                    positions={shape.points}
                                                    pathOptions={{ color: shape.color }}
                                                    myId={shape.myId}
                                                    eventHandlers={{
                                                        click: (e) => {
                                                            const leafletShape = Object.values(mapRef.current._layers).find(layer => layer.options.myId === shape.myId);
                                                            enableEditingOnShape(leafletShape, shape.myId);
                                                        }
                                                    }}
                                                />
                                            );
                                        } else if (shape.type === 'polyline') {
                                            return (
                                                <Polyline
                                                    key={idx}
                                                    positions={shape.points}
                                                    pathOptions={{ color: shape.color }}
                                                    myId={shape.myId}
                                                    eventHandlers={{
                                                        click: (e) => {
                                                            const leafletShape = Object.values(mapRef.current._layers).find(layer => layer.options.myId === shape.myId);
                                                            enableEditingOnShape(leafletShape, shape.myId);
                                                        }
                                                    }}
                                                />
                                            );
                                        }
                                        return null;
                                    })}
                                </FeatureGroup>
                            </MapContainer>
                        </div>
                        <div style={{ margin: '2rem 5% 0 0' }}>
                            <div>
                                <p style={{ marginLeft: '1rem', fontWeight: 'bold', fontSize: '2rem' }}>Ajouter Danger Fixe</p>
                                <FormControl className='formDanger' style={{ width: '100%' }}>
                                    <InputLabel id="dangerId-label">Sélectionnez une forme</InputLabel>
                                    <Select
                                        name="dangerId"
                                        id="dangerId"
                                        labelId="dangerId-label"
                                        value={selectedDanger}
                                        onChange={e => setSelectedDanger(e.target.value)}
                                    >
                                        <MenuItem value="">Sélectionnez une forme</MenuItem>
                                        <MenuItem value="Polyline">Polyline</MenuItem>
                                        <MenuItem value="Polygon">Polygon</MenuItem>
                                        <MenuItem value="Circle">Circle</MenuItem>
                                    </Select>
                                </FormControl>


                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setIsDrawing(true)}
                                    style={{
                                        maxWidth: '90px',
                                        marginLeft: '1rem',
                                        marginTop: '1rem',
                                        visibility: selectedDanger && !isDrawing ? 'visible' : 'hidden',
                                    }}
                                >
                                    Ajouter
                                </Button>



                                <Button
                                    variant="contained"
                                    color="warning"
                                    onClick={handleCancelDrawing}
                                    style={{
                                        maxWidth: '90px',
                                        marginLeft: '1rem',
                                        marginTop: '1rem',
                                        visibility: isDrawing || isEditing ? 'visible' : 'hidden',
                                    }}
                                >
                                    Annuler
                                </Button>


                                {/* Bouton pour supprimer une forme existante */}

                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={handleDeleteShape}
                                    style={{
                                        maxWidth: '90px',
                                        marginLeft: '1rem',
                                        marginTop: '1rem',
                                        visibility: isEditing && !isDrawing ? 'visible' : 'hidden',
                                    }}
                                >
                                    Supprimer
                                </Button>

                            </div>
                            <div>
                                <p style={{ marginTop: '1rem', marginLeft: '1rem', fontWeight: 'bold', fontSize: '2rem' }}>Mes Dangers Fixes</p>
                                <FormControl className='formDanger' style={{ width: '100%' }}>
                                    <InputLabel id="shapeCategory-label">Sélectionnez une catégorie</InputLabel>
                                    <Select
                                        name="shapeCategory"
                                        id="shapeCategory"
                                        labelId="shapeCategory-label"
                                        value={shapeCategory}
                                        onChange={e => setShapeCategory(e.target.value)}
                                    >
                                        <MenuItem value="polyline">Polylines</MenuItem>
                                        <MenuItem value="polygon">Polygons</MenuItem>
                                        <MenuItem value="circle">Circles</MenuItem>
                                    </Select>
                                </FormControl>

                                {/* Affichage des formes selon la catégorie sélectionnée */}
                                <div style={{ marginLeft: '1rem', marginTop: '1rem' }}>
                                    {shapeCategory === 'polyline' && (
                                        <>
                                            {drawnShapes.filter(shape => shape.type === 'polyline').length === 0 && (
                                                <p>Aucun polyline</p>
                                            )}
                                            <ul>
                                                {drawnShapes
                                                    .filter(shape => shape.type === 'polyline')
                                                    .map((shape, idx) => (
                                                        <li key={idx} onClick={() => {
                                                            (
                                                                handleShapeSelectionFromList(shape)
                                                            )
                                                        }}>
                                                            <strong>Polyline {idx + 1}</strong> - {shape.points.length} pts
                                                        </li>
                                                    ))}
                                            </ul>
                                        </>
                                    )}
                                    {shapeCategory === 'polygon' && (
                                        <>
                                            {drawnShapes.filter(shape => shape.type === 'polygon').length === 0 && (
                                                <p>Aucun polygon</p>
                                            )}
                                            <ul>
                                                {drawnShapes
                                                    .filter(shape => shape.type === 'polygon')
                                                    .map((shape, idx) => (
                                                        <li key={idx} onClick={() => handleShapeSelectionFromList(shape)}>
                                                            <strong>Polygon {idx + 1}</strong> - {shape.points.length} pts
                                                        </li>
                                                    ))}
                                            </ul>
                                        </>
                                    )}
                                    {shapeCategory === 'circle' && (
                                        <>
                                            {drawnShapes.filter(shape => shape.type === 'circle').length === 0 && (
                                                <p>Aucun circle</p>
                                            )}
                                            <ul>
                                                {drawnShapes
                                                    .filter(shape => shape.type === 'circle')
                                                    .map((shape, idx) => (
                                                        <li key={idx} onClick={() => handleShapeSelectionFromList(shape)}>
                                                            <strong>Circle {idx + 1}</strong> - Radius: {Math.round((shape.radius * 10)) / 10} meters
                                                        </li>
                                                    ))}
                                            </ul>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

const ChangeMapView = ({ coords }) => {
    const map = useMap();

    useEffect(() => {
        if (coords.lat && coords.long && coords.rayon) {
            const lat = parseFloat(coords.lat);
            const long = parseFloat(coords.long);
            const rayon = parseFloat(coords.rayon);

            if (!isNaN(lat) && !isNaN(long) && !isNaN(rayon)) {
                const offset = rayon / 200000;
                const bounds = [
                    [lat - offset, long - offset],
                    [lat + offset, long + offset],
                ];

                map.fitBounds(bounds, { padding: [50, 50] });
            } else {
                console.error("Invalid coordinates or radius:", lat, long, rayon);
            }
        }
    }, [coords, map]);

    return null;
};

export default DangersFixes;